import "./Styles/BouttonPublier.css";

const BouttonPublier = ( { article, collectDatas, type }) => {


    return (
        <div>
            <button onClick={collectDatas} className="button2 adminButton">
                {type} l'article
            </button>
        </div>
    );
};
export default BouttonPublier;
