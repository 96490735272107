import "./Styles/OldLogout.css";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {OldAuthContext} from "./Context/OldAuthContext";
import { Dialog } from "@reach/dialog";


function Oldlogout() {
    let navigate = useNavigate();

    const { setAccess } =
        useContext(OldAuthContext);

    let disc = () => {setAccess(false);localStorage.clear();navigate('/connexion'); }


    return (
        <Dialog>
            <div className="fragmentContainer">
                <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                <div className="popUpModalDelet">
                    <div className="modalContainer">
                        <h2>
                            Êtes-vous sûr de vouloir de vous déconnecter ?
                        </h2>

                        <div className="buttonModalDelet">
                            <button className="buttonGreen" onClick={() => navigate(-1)}>
                                Retour
                            </button>
                            <button
                                className="buttonGreen"
                                onClick={() => disc()}>
                                Se déconnecter
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default Oldlogout;
