import axios from "axios";
import {useState, createContext} from "react";
import {useNavigate} from "react-router-dom";

export const OldAuthContext = createContext();

const OldAuthContextProvider = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [badCredential, setBadCredential] = useState(false);
    const [accessRefused, setAccessRefused] = useState(false);
    const [passChg, setPassChg] = useState(false);
    const [active, setActive] = useState(false);
    let [access, setAccess] = useState(false);

    const navigate = useNavigate();
    const handleSubmit = (e) => {
        // debugger;
        e.preventDefault();
        axios
            .post(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/auth/login`, {
                // mail: e.email,
                mail:  email,
                // password: e.password,
                password: password,
            })
            .then(async (res) => {
                localStorage.setItem("token", res.headers["x-access-token"]);
                await axios.post(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/auth/whoami`, {token: localStorage.getItem('token')}).then((response) => {
                    localStorage.setItem('username', response.data.username)
                });
                protectedRoute();
            }).catch((err) => {
            setBadCredential(true);
            setAccessRefused(false);
            setPassChg(false);
        });
    };
    const protectedRoute = (redirect) => {
        const token = localStorage.getItem("token");
        console.log('token verified !')
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/auth/protected`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((result) => {
                setBadCredential(false);
                setAccessRefused(false);
                setPassChg(false);
                setAccess(true);
                if (redirect){
                    navigate("/admin-controler/selectPanel");
                }
            })
            .catch((err) => {
                localStorage.removeItem('token');
                setBadCredential(true);
                setAccessRefused(false);
                setPassChg(false);
                setAccess(false);
                navigate("/connexion");
            });
    };


    return (
        <OldAuthContext.Provider
            value={{
                handleSubmit,
                protectedRoute,
                badCredential,
                setBadCredential,
                accessRefused,
                setAccessRefused,
                passChg,
                setPassChg,
                setAccess,
                active,
                email,
                access,
                setEmail,
                password,
                setPassword,
                setActive
            }}
        >
            {props.children}
        </OldAuthContext.Provider>
    );
};

export default OldAuthContextProvider;
