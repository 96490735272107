/* import CSS */
import "./styles/Card_Focus_2_Selected.css"
import {BUTTON_BLHS} from "./BUTTON_BLHS";
import {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";



export const Card_Focus_2_Selected = (props) =>{

    let subCategoryName = useContext(DisplayContext).subCategoryName

    let index = props.index -1
    let row = Math.floor(index / 3 ) + 1
    let column = index % 3  + 1
    let isArticleSelected = useContext(DisplayContext).articleSelected
    let setArticle = useContext(DisplayContext).setArticle
    let article = useContext(DisplayContext).articleContent
    let selectArticle = () =>{
        setArticle(props.id)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return(
        <div class="Card_Focus_2_Selected_Container"
        style={{gridColumn: column, gridRow: row}}
        >
            <img
            // src="https://images.pexels.com/photos/262577/pexels-photo-262577.jpeg?cs=srgb&dl=pexels-pixabay-262577.jpg&fm=jpg"
            src={props.image}
            alt="Something put down here"
            class="Card_Focus_2_Selected_img"
            />

            <p class="Card_Focus_2_Selected_CAT_NAME">
                {subCategoryName}
            </p>
            <p className="Card_Focus_2_Selected_INTRO">
                {props.titre}
            </p>

            <div className="Card_Focus_2_Selected_Button"                 onClick={selectArticle}>
                <div style={{marginTop: '10px'}}

                >Lire L'article </div>
            </div>
        </div>
    )
}
