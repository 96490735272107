/* Import Css Style */
import './styles/Focus3_sub.css'
import {NumberCircle} from "./NumberCircle";

export const Focus3_sub = (props) =>{
    return(
        <div class="Focus3_sub_container">
            <NumberCircle
                class={"num_focus3_sub_img"}
                textColor={'#ffffff'}
                wrapColor={'var(--clr-purple-text)'}
                number={props.number}
            /> {/* color num */}
            <p>
            <p class="focus_3_sub_title">{props.title} </p>
            <p className="focus_3_sub_text">{props.text} </p>
            </p>
        </div>
    )
}
