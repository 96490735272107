/* Import Css Style */
import './styles/DropDownTown.css'
import {CategoryContext} from "../Contexte/CategoryContext";
import React, {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import {CitiesContext} from "../Contexte/CityContext";



export const DropDownTown = (props) => {

    let cities = useContext(CitiesContext).villes
    let setVille = useContext(DisplayContext).setVille
    let displayDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'flex';
    }

    let hideDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'none';
    }

    let ableDisable = () => {
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        if (cols[0].style.display == 'none'){
            displayDropDown()
        }
        else{
            hideDropDown()
        }
    }
    return(
        <p className="DropDownTownContainer">

            {
                cities &&
                cities.map((cat) => {
                    if (cat.visible === 1) {
                        return (
                            <p class="DropDownTownItem" onClick={
                                () => {
                                    setVille(cat.id)
                                    ableDisable()
                                }
                            }>

                                {cat.value}

                            </p>
                        )
                    }
                    else{
                        return (<></>)
                    }
                })
            }

        </p>
    )

}
