import './SearchBar.css'
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//*IMPORT REACT//*
import Select from './Select'
import { useContext } from 'react'
import {DisplayContext} from "../Contexte/DisplayContext";
import {CitiesContext} from "../Contexte/CityContext";
import {CategoryContext} from "../Contexte/CategoryContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";
import {ArticleContext} from "../Contexte/AritcleContext";

const SearchBar = (props) => {
    // Listes des données
    const { villes } = useContext(CitiesContext).villes
    const { categories } = useContext(CategoryContext).categories
    const { sousCategories } = useContext(SubCategoriesContext).sousCategories

    // State des Select
    const { idCategorie } = useContext(DisplayContext).category
    const { setIdCategorie } = useContext(DisplayContext).setCategory

    const { idVille } = useContext(DisplayContext).ville
    const { setIdville } = useContext(DisplayContext).setVille


    const { idsousCategorie } = useContext(DisplayContext).subCategory
    const { setIdsousCategorie } = useContext(DisplayContext).setSubCategory
    const { searchFilter } = useContext(ArticleContext)
    const { setSearchFilter } = useContext(ArticleContext)

    // Fonction Rechercher
    const { deleteFilter } = useContext(ArticleContext)
    const { deleteSearchHome } = useContext(ArticleContext)
    return (
        <div className='holderSearchBar'>
            <div className='SearchBar'>
                {props.needText ?
                    <input
                        type='text'
                        name='searchBar'
                        placeholder='Rechercher'
                        value={searchFilter}
                        onChange={(e) => setSearchFilter(e.target.value)}
                    ></input> : <></>
                }
                {/* SELECT Ville  */}
                {(props.isVille) && (

                    <>
                        salam
                    <Select
                        name={'Ville'}
                        result={villes.filter(v => v.visible === 1)}
                        value={idVille}
                        set={setIdville}
                    />
                    </>
                )}

                {/* SELECT CATEGORIE */}
                {props.isCat && (
                    <Select
                        name={'Catégories'}
                        result={categories}
                        value={idCategorie}
                        set={setIdCategorie}
                    />
                )}

                {/* SELECT SOUS CAT */}
                {props.isSousCat && (
                    <Select
                        name={'Sous-catégorie'}
                        result={sousCategories}
                        value={idsousCategorie}
                        set={setIdsousCategorie}
                    />
                )}

                {props.isButtonGrid && (
                    <div className='searchButtons'>
                        <button
                            className='gridSearchButtons'
                            onClick={() => deleteFilter()}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                size='2x'
                                color='var(--clr-white)'
                                className='reloadIcon'
                            />
                        </button>
                    </div>
                )}
                {props.isButtonHome && (
                    <div className='searchButtons'>
                        <button
                            className='HolderReloadIcon'
                            onClick={() => deleteSearchHome()}
                        >
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                size='2x'
                                color='var(--clr-white)'
                                className='reloadIcon'
                            />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )

}
export default SearchBar
