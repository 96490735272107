import axios from "axios";
import { useEffect, useState, createContext, useContext } from "react";

export const SubCategoriesContext = createContext();

const SubCategoriesContextProvider = (props) => {

    const [sousCategories, setSousCategories] = useState([]);
    const [reloadSousCat, setReloadSousCat] = useState(true);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/souscategories/`)
            .then((res) =>{
                setSousCategories(res.data);
            });
    }, [reloadSousCat]);

    return (
        <SubCategoriesContext.Provider
            value={{    sousCategories, reloadSousCat, setReloadSousCat }}
        >
            {props.children}
        </SubCategoriesContext.Provider>
    );
};

export default SubCategoriesContextProvider;
