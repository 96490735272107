import './styles/Donation.css'


export const Donation = (props) => {

    return(
        <div class={"DonationBiggestContainer" + (props.isVisible ? ' upSoutien' : ' ')}>
            Je fais un don
        </div>
    )
}
