import './styles/ArticleDisplay.css'
import {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import {Header_Focus1} from "./Header_Focus1";


export const ArticleDisplay = (props) =>{
    function createMarkup(result) {
        return { __html: `${result}` };
    }
    let article = useContext(DisplayContext).articleContent
    return(
        <div className="ArticleDisplayBiggerContainer">
            <div>
                <Header_Focus1/>
            </div>
        <div class="ArticleDisplay_Container">

            <img
                src={article.image}
                alt={'Image darticle'}
                className={"ArticleDisplayImage"}
            />
            <p className={'ArticleDisplayHeader'} >
            <div className="catNameArticleDisplay"> {article.nom_sous_categorie} </div>
            <div className="NameArticleDisplay"> {article.titre} </div>
            </p>
            <p className="articleDetailPara" dangerouslySetInnerHTML={createMarkup(article.para1)} ></p>
        </div>
        </div>
    )
}
