/* Import Css Style */
import './styles/Header_Focus2.css'
/* Other Components */
import {Card_Focus_2} from "./Card_Focus_2";
/* External assets */
import Astronaut from './../assets/21.png'
import Satellite from './../assets/24.png'
import meteor from './../assets/18.png'
import SpaceShip from './../assets/25.png'
import planet from './../assets/20.png'
import eloneIcon from './../assets/logo_white_01.png'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";


// import required modules
import { Autoplay, Pagination, Navigation, Grid } from "swiper";
import EloneIcon from "../assets/ELONE_Logo_V1_white_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.svg";


export const Header_Focus2 = () =>{
    let normal_render = () => {
        return(

            <div class="SwipperContainah">
                <Swiper
                    modules={[Navigation, Pagination]}
                    navigation={true}
                    slidesPerView={3}
                    pagination={true}
                    className="mySwiper"

                >
                    <SwiperSlide>
                        <Card_Focus_2
                            index="01"
                            indexDB="82"
                            content="Je débute : let's go !"
                            textColor="var(--clr-white1)"
                            indexColor="var(--clr-purple4)"
                            borderColor=""
                            wrapColor="var(--clr-headerColor)"
                            alt="Astronaute"
                            img={Astronaut}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card_Focus_2
                            index="02"
                            indexDB="83"
                            content="Je veux être conseillé pour tout déchirer !"
                            textColor="var(--clr-white1)"
                            indexColor="var(--clr-purple4)"
                            borderColor=""
                            wrapColor="var(--clr-headerColor)"
                            alt="Satellite"
                            img={Satellite}
                            lift={true}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card_Focus_2
                            index="03"
                            indexDB="84"

                            content="Je cherche des financements, pour accélérer !"
                            // textColor="var(--clr-purple4))"
                            textColor="var(--clr-white1)"
                            indexColor="var(--clr-purple4)"
                            borderColor=""
                            wrapColor="var(--clr-headerColor)"
                            alt="Meteor"
                            img={meteor}

                        />
                    </SwiperSlide>

                    <SwiperSlide>
                        <Card_Focus_2
                            index="04"
                            indexDB="85"
                            content="J'ai besoin de locaux pour m'imposer !"
                            textColor="var(--clr-white1)"
                            indexColor="var(--clr-purple4)"
                            borderColor=""
                            wrapColor="var(--clr-headerColor)"
                            alt="SpaceShip"
                            img={SpaceShip}
                            lift={true}

                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card_Focus_2
                            index="05"
                            indexDB="99"
                            content="Je veux gagner un concours !"
                            textColor="var(--clr-white1)"
                            indexColor="var(--clr-purple4)"
                            borderColor=""
                            wrapColor="var(--clr-headerColor)"
                            alt="Planet"
                            img={planet}
                            urlTo={'https://www.lancetonidee.com/appels-projets/'}
                            noContext={true}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
    let small_monitor_render =() => {
        return(

            <>
                <Swiper
                    spaceBetween={5}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <p className="card_Container_Focus_2">
                            <Card_Focus_2
                                index="01"
                                content="Je débute : let's go !"
                                textColor="var(--clr-purple4))"
                                borderColor=""
                                wrapColor="#8447E7"
                                alt="Astronaute"
                                img={Astronaut}
                            />
                            <Card_Focus_2
                                index="02"
                                content="Je veux être conseillé pour tout déchirer !"
                                textColor="var(--clr-purple4))"
                                borderColor=""
                                wrapColor="var(--clr-headerColor)"
                                alt="Satellite"
                                img={Satellite}
                            />
                            <Card_Focus_2
                                index="03"
                                content="Je cherche des financements, pour accélérer !"
                                textColor="var(--clr-purple4))"
                                borderColor=""
                                wrapColor="var(--clr-headerColor)"
                                alt="Meteor"
                                img={meteor}
                            />
                            <Card_Focus_2
                                index="04"
                                content="J'ai besoin de locaux pour m'imposer !"
                                textColor="var(--clr-purple4))"
                                borderColor=""
                                wrapColor="var(--clr-headerColor)"
                                alt="SpaceShip"
                                img={SpaceShip}
                            />

                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <p className="card_Container_Focus_2">
                            <Card_Focus_2
                                index="05"
                                content="Je veux gagner un concours !"
                                textColor="var(--clr-purple4))"
                                borderColor=""
                                wrapColor="var(--clr-headerColor)"
                                alt="Planet"
                                img={planet}
                                noContext={true}
                            />
                        </p></SwiperSlide>
                </Swiper>
            </>

        )

    }
    let phone_render = () => {
        return(
            <Swiper
                slidesPerView={1}
                autoplay={{
                    delay: 300000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <Card_Focus_2
                        index="01"
                        indexDB="82"
                        content="Je débute : let's go !"
                        textColor="var(--clr-white1)"
                        indexColor="var(--clr-purple4)"
                        borderColor=""
                        wrapColor="var(--clr-headerColor)"
                        alt="Astronaute"
                        img={Astronaut}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Card_Focus_2
                        index="02"
                        indexDB="83"
                        content="Je veux être conseillé pour tout déchirer !"
                        textColor="var(--clr-white1)"
                        indexColor="var(--clr-purple4)"
                        borderColor=""
                        wrapColor="var(--clr-headerColor)"
                        alt="Satellite"
                        img={Satellite}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Card_Focus_2
                        index="03"
                        indexDB="84"
                        content="Je cherche des financements, pour accélérer !"
                        // textColor="var(--clr-purple4))"
                        textColor="var(--clr-white1)"
                        indexColor="var(--clr-purple4)"
                        borderColor=""
                        wrapColor="var(--clr-headerColor)"
                        alt="Meteor"
                        img={meteor}

                    />
                </SwiperSlide>

                <SwiperSlide>
                    <Card_Focus_2
                        index="04"
                        indexDB="85"
                        content="J'ai besoin de locaux pour m'imposer !"
                        textColor="var(--clr-white1)"
                        indexColor="var(--clr-purple4)"
                        borderColor=""
                        wrapColor="var(--clr-headerColor)"
                        alt="SpaceShip"
                        img={SpaceShip}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Card_Focus_2
                        index="05"
                        indexDB="99"
                        content="Je veux gagner un concours !"
                        textColor="var(--clr-white1)"
                        indexColor="var(--clr-purple4)"
                        borderColor=""
                        wrapColor="var(--clr-headerColor)"
                        alt="Planet"
                        img={planet}
                        urlTo={'https://www.lancetonidee.com/appels-projets/'}
                        noContext={true}
                    />
                </SwiperSlide>
            </Swiper>
        )

    }
    let is_small_monitor= () => {
        return window.innerWidth < 970
    }

    let is_phone = () => {
        return window.innerWidth < 650
    }
    return(
        <>
            <div class="Header_Focus2_Container">
                <img
                    src={EloneIcon}
                    alt={'EloneIcon'}
                    style={{textAlign: 'center', left: '40%', marginLeft: '45%'}}
                    className={'Focus2EloneIcon'}
                />
                <p class="FirstText_Focus2">
                    <div style={{margin:'0px'}} class="SubFirstText_Focus2">
                        Vous êtes de Grenoble et de ses alentours ?
                    </div>
                    {/*<br />*/}
                    Dans quelle situation êtes-vous ?</p>
                {/*<div style={{width: '100%'}}>*/}
                <hr class="hr_Focus2">
                </hr>
                {/*{ is_small_monitor() ? (*/}
                {/*    is_phone() ? phone_render()  :*/}
                {/*    small_monitor_render()) : normal_render()  }*/}
                {is_phone() ? phone_render()  : normal_render()  }

            </div>


        </>
    )
}
