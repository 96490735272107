/* Import Css Style */
import './styles/Focus_Formulaire.css'
import {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'


export const Focus_Formulaire = () =>{
    const [step, setStep] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");


    const options = {
        from: 'Rachid@sosjp.org',
        to: 'BassamGraini@outlook.com',
        subject: 'hello world',
    };

    function validateEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    function validateEmail(name) {
        if (name.length > 0){
            return true
        }
        return false;
    }

    function handleChangeName(event) {
        setName(event.target.value);
    }
    function handleChangeEmail(event) {

        setEmail(event.target.value);

    }
    let ret = <> Erreur </>
    switch (step){
        case 1:
            ret =<>
            <div className="titleFormulaire">
                Hello <br/>
                <input type="text" value={name} onChange={handleChangeName} placeholder="QUEL EST VOTRE PRÉNOM?"/>
            </div>

            </>
            break;
        case 2:
            ret =<>
                <div className="titleFormulaire">
                    {name} <br/>
                    <input type="text" value={email} onChange={handleChangeEmail} placeholder="QUEL EST VOTRE EMAIL?"/>
                </div>

            </>
            break;
        case 3:
            ret =<>
                <div className="titleFormulaire">
                    DE QUOI AVEZ-VOUS BESOIN ?
                    <div className="CaracContainer">
                        <div className="CaracElem">
                            Je Débute
                        </div>
                        <div className="CaracElem">
                            Je Veux Être Conseillé
                        </div>
                        <div className="CaracElem">
                            Je Cherche Des Financements
                        </div>
                        <div className="CaracElem">
                            J'ai Un Projet
                        </div>
                        <div className="CaracElem">
                            J'ai Besoin De Locaux
                        </div>
                        <div className="CaracElem">
                            J'ai Besoin D'aide Pour Un Dossier De Concours
                        </div>
                        <div className="CaracElem">
                            J'ai Besoin D'un Devis Pour Un Financement
                        </div>
                        <div className="CaracElem">
                            J'ai Besoin De Conseils, Autres
                        </div>
                    </div>
                </div>

            </>
            break;
        default:
            console.log('Today is something else');
    }

    return(
        <div className="Focus_Formulaire_Container">
            <div className="Header_Formulaire">
                FORMULAIRE DE CONTACT <br/> Étape {step} / 3
            </div>
            {ret}
            <br/>
            <div className="ErrorMsgFormulaire">
                {error}
            </div>
            <div className="PrevNextFormulaire" >
                { step > 1 &&
                <div className="PrevFormulaire" onClick={()=> {
                    setError('');
                    setStep(step - 1)}}>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                    Précédent
                </div>
                }
                <div className="NextFormulaire" onClick={()=> {
                    if (step === 2)
                    {
                        if (!validateEmail(email)) {
                            setError('VEUILLEZ ENTRER UNE ADRESSE EMAIL');
                        }
                        else {
                            setError('');

                            setStep(step+ 1)
                        }
                    }
                    else if (step === 1)
                    {
                        if (!validateEmail(name)) {
                            setError('VEUILLEZ ENTRER UN NOM OU UN PRÉNOM');
                        }
                        else {
                            setError('');
                            setStep(step+ 1)
                        }
                    }
                    else{
                        setError('');
                        setStep(step+ 1);
                    }

                }}>
                    Étape Suivante
                </div>

            </div>
        </div>
    )
}
