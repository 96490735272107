/* Import Css Style */
import './styles/BLHS_Focus1.css'

/* External Components */
import {BUTTON_BLHS} from "./BUTTON_BLHS";

export const BLHS_Focus1 = () =>{
    return(
        <div class="BLHS_Focus1_Container">
            <p class="FirstText">

                <div class="firstPartText">L'Association  <br /> "Service Assistance Dépannage" <br /> </div >

                des entrepreneurs des quartiers de <div class="UnderLineHeader">Grenoble-Alpes Métropole</div></p>
            <hr class="BLHS_hr"/>
            <p class="SecondText"> <div style={{marginBottom: '20px'}}>Parce que l'Entrepreneuriat et la Tech ne devraient pas être aussi compliqués ! </div>
            Le CitésLab de BpiFrance est là pour vous propulser <br/> <div className="UnderLineHeader" style={{fontWeight: 'bolder'}}>GRATUITEMENT !</div>
            </p>
            <div class="BLHS_BUTTONS_CONTAINER">
                <BUTTON_BLHS
                content="Discutons de votre projet"
                textColor="var(--clr-purple-text)"
                wrapColor="var(--clr-headerColor)"
                borderColor="var(--clr-purple-text)"
                wrapHoverColor="black"
                large={'true'}
                urlTo='https://www.qrcodechimp.com/page/rtez8b1x8ogw?v=chk1682186128#'
                />
                {/*<BUTTON_BLHS*/}
                {/*    content="Contact"*/}
                {/*    textColor="var(--clr-white1)"*/}
                {/*    wrapHoverColor="var(--clr-purple10)"*/}

                {/*    wrapColor=""*/}
                {/*     borderColor="var(--clr-purple-text)"*/}
                {/*/>*/}
            </div>

        </div>
    )
}
