/* Import Css Style */
import './styles/APropos.css'

/* Components imports */
import React, {useEffect, useRef, useState} from "react";
import { Header_Focus1 } from '../subComponents/Header_Focus1';
import { FooterHome } from '../components/FooterHome';
import { FooterHome_Comp3 } from '../subComponents/FooterHome_Comp3';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { BUTTON_BLHS } from '../subComponents/BUTTON_BLHS';
export const APropos = (props) =>{

    return(
        <div className={"Apropos_BiggestContainer"}>

        <Header_Focus1/>
        <h2 className='aproposTitle'>Qui nous-sommes? </h2>
        <div className='aproposBody'>
        <p>
        SOS Jeunes pousses est une association Échirolloise loi 1901 qui porte un dispositif d’état le Citéslab de BPIFrance, des services civiques ambassadeurs du développement informatique et algorithme et du dispositif Engager pour les jeunes en rupture qui a pour but, la détection, la préparation et l’employabilité durable à travers la création d’activités par les habitants des quartiers. Sosjp vise à soutenir et accompagner le développement économique et social des trois Quartiers Prioritaires de la Ville (QPV) d'Échirolles. (Luire-Viscose, Village Sud, Villeneuve D’Échirolles)
        </p>
        <p>
Le programme Citéslab a été conçu dans le but de favoriser la création d’activité, l'entrepreneuriat et l'inclusion dans les QPV en mobilisant les compétences et les ressources de Bpi France et de SOS Jeunes Pousses. Grâce à cette collaboration, les habitants des QPV bénéficient d'un soutien concret pour développer leurs projets entrepreneuriaux, renforcer leurs compétences professionnelles et créer des opportunités économiques durables au sein de leur communauté.
</p>
<p>

Les objectifs du programme Citéslab sont multiples (Détecter, Préparer et Propulser) :
<lu>

<li>Encourager l'émergence de projets innovants : En offrant un accompagnement sur mesure, Citéslab aide les porteurs de projets à développer leurs idées innovantes et à les concrétiser.</li>
<li>Stimuler l'emploi local : Le programme vise à créer des opportunités d'emploi au sein des QPV en soutenant la création et le développement d'entreprises locales.</li>
<li>Renforcer le tissu économique local : Citéslab encourage la collaboration entre les entrepreneurs locaux et favorise la dynamisation du tissu économique dans les quartiers ciblés.</li>
</lu>
</p>
<p>

Pour atteindre ces objectifs, Citéslab met en place différentes actions et dispositifs de propulsion :
<lu>
<li>Formation et mentorat : Les porteurs de projets bénéficient de formations adaptées à leurs besoins, ainsi que d'un suivi personnalisé.</li>
<li>Accès au financement : Citéslab facilite l'accès aux financements et aux aides publiques pour les projets jugés prometteurs.</li>
<li>Mise en réseau : Le programme favorise la mise en relation des entrepreneurs avec des acteurs économiques et institutionnels locaux, renforçant ainsi leur ancrage territorial.</li>
<li>Appui technique : Les porteurs de projets peuvent bénéficier d'un appui technique pour développer leur produit ou service.</li>
</lu>

</p>
<p>

Le programme Citéslab aspire à avoir un impact significatif sur les QPV d'Échirolles en :
<lu>

<li>Favorisant l'émergence de nouvelles entreprises et de projets à fort potentiel de croissance.</li>
<li>Créant des emplois durables et locaux, contribuant ainsi à l'amélioration du dynamisme économique des quartiers.</li>
<li>Renforçant la cohésion sociale en stimulant l'entreprenariat et en impliquant les habitants dans la dynamique économique locale.</li>
<li>Sensibilisation à l'entrepreneuriat et à l'innovation : Citéslab organise des événements, des ateliers et des rencontres pour sensibiliser les habitants des QPV à l'entrepreneuriat. Ces initiatives visent à susciter l'esprit d'entreprise, à inspirer les jeunes générations et à promouvoir une culture entrepreneuriale au sein des communautés locales.</li>
</lu>


</p>
<p>
En résumé, le programme Citéslab de Bpi France porté par SOS Jeunes Pousses est une initiative majeure pour le développement économique et social des QPV d'Échirolles. Grâce à un accompagnement personnalisé, des formations ciblées et un soutien à la création d’activité, le programme vise à renforcer les compétences entrepreneuriales des habitants et à créer un écosystème favorable à l'innovation (ESS) et à l'entrepreneuriat au sein de la communauté.
</p>
            
        </div>
        <div className='downloaderContainer'>

            <div className='clickHereAporposContainer'>
                <Link to="/Apropos/Statuts 2023 SOSJP.pdf" target="_blank" download >
                <FontAwesomeIcon icon={faCoins} className='AproposIcon'/>
                </Link>
                <BUTTON_BLHS
                    content = "Statuts SOSJP 2023"
                    textColor="var(--clr-purple-text)"
                    wrapColor="var(--clr-headerColor)"
                    borderColor="var(--clr-purple-text)"
                    wrapHoverColor="black"
                    class="BUTTON_FOCUS4_COMP2"
                    urlTo="/Apropos/Statuts 2023 SOSJP.pdf"
                >                    
                <Link to="/Apropos/Statuts 2023 SOSJP.pdf" target="_blank" download >
                Télécharger le document                 </Link>
                
                </BUTTON_BLHS>
            </div>
            
            <div className='clickHereAporposContainer'>
                <Link to="/Apropos/Pv AGE 2023 SPSJP.pdf" target="_blank" download >
                <FontAwesomeIcon icon={faGavel} className='AproposIcon'/>
                </Link>
                    
                <BUTTON_BLHS
                    content = "PV SOSJP 2023"
                    textColor="var(--clr-purple-text)"
                    wrapColor="var(--clr-headerColor)"
                    borderColor="var(--clr-purple-text)"
                    wrapHoverColor="black"
                    class="BUTTON_FOCUS4_COMP2"
                    urlTo="/Apropos/Pv AGE 2023 SPSJP.pdf"
                >
                <Link to="/Apropos/Pv AGE 2023 SPSJP.pdf" target="_blank" download >
                    Télécharger le document </Link>
                    </BUTTON_BLHS>
            </div>

            
            <div className='clickHereAporposContainer'>
                <Link to="/Apropos/CompteCertifié.pdf" target="_blank" download >
                <FontAwesomeIcon icon={faCertificate} className='AproposIcon'/>
                </Link>
                <BUTTON_BLHS
                    content = "Compte cértifié 2022"
                    textColor="var(--clr-purple-text)"
                    wrapColor="var(--clr-headerColor)"
                    borderColor="var(--clr-purple-text)"
                    wrapHoverColor="black"
                    class="BUTTON_FOCUS4_COMP2"
                    urlTo="/Apropos/CompteCertifié.pdf"
                >
                <Link to="/Apropos/CompteCertifié.pdf" target="_blank" download >
                    Télécharger le document </Link></BUTTON_BLHS>
            </div>

        </div>

        <FooterHome_Comp3/>
        </div>
    )

}
