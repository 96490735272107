import {createContext, useContext, useEffect, useState} from "react";
import DisplayContextProvider, {DisplayContext} from "./DisplayContext";
import axios from "axios";

export const ArticleContext = createContext();


const ArticleContextProvider = (props) => {
    const [articles, setArticles] = useState([]);
    const [reloadArticle, setReloadArticle] = useState(true);
    const [filters, setFilters] = useState("");
    const [searchFilter, setSearchFilter] = useState();


    let idsousCategorie = useContext(DisplayContext).subCategory
    let setIdsousCategorie = useContext(DisplayContext).setSubCategory
    let idCategorie = useContext(DisplayContext).category
    let setIdCategorie = useContext(DisplayContext).setCategory

    useEffect( () => {
        searchLaunch()
    }, [reloadArticle])

    useEffect(() => {
        let filter = [];

        idCategorie && filter.push(`categorie=${idCategorie}`);
        idsousCategorie && filter.push(`sousCategorie=${idsousCategorie}`);
        searchFilter && filter.push(`search=${searchFilter}`);

        setFilters(`?${filter.join("&")}`);
        setReloadArticle(!reloadArticle)
    }, [idCategorie, idsousCategorie, searchFilter]);

    const deleteFilter = () => {
        setIdsousCategorie("");
        setIdCategorie("35");
        resetSearch();
    };
    const resetSearch = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/?categorie=${idCategorie}`

            )
            .then((res) => {setArticles(res.data); }

            );
    };
    const searchLaunch = async () => {
        await axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles${filters}`)
            .then((res) => {setArticles(res.data);});
    };


    return (
        <ArticleContext.Provider

            value={{ articles}}
        >

            {props.children}
        </ArticleContext.Provider>
            );
}
export default ArticleContextProvider;
