/* Import Css Style */
import './styles/Focus3.css'
/* External Component */
import {BUTTON_BLHS} from "../subComponents/BUTTON_BLHS";
import {Focus3_sub} from "../subComponents/Focus3_sub";
/* External assets */
import {faArrowRightLong, faCircle} from "@fortawesome/free-solid-svg-icons"
import startup_diversity from "./../assets/Focus3/startup_diversity.jpeg"
import startup_diversity_2 from "./../assets/Focus3/shutterstock_2205301441.jpg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Focus3 = () =>{

    let scrollNextF3 = () => {
        document.querySelector('.Focus4_Container').scrollIntoView({behavior: "smooth"
            , block: 'center'
        })
    }

    return(
        <div class="Focus3_Container">
            <div class="Focus3_row_1">
                <div class="Focus3_row_1_column_1">
                    <img src={startup_diversity} alt={"diversity"} class={"diversity_focus3"} />
                </div>
                <img src={startup_diversity_2} alt={"diversity"} className={"women_1_cropped_focus3"}/>
                <div className="Focus3_row_1_column_2">
                    <p class="Focus3_row_1_column_2_text_1 yui3-cssreset">
                        <div>Quand l’innovation <br/> entrepreneuriale rencontre </div> l’inclusion et la <div class={"UnderLineHeader"} style={{color: 'var(--clr-white1)', fontSize:'2.35rem'}}>diversité</div> ! </p>
                    {/*<hr class="Focus3_hr"/>*/}
                    <p className="Focus3_row_1_column_2_text_2">
                        À travers l'association SOS Jeunes Pousses, Rachid et Myriam Djebbouri sensibilisent, détectent, préparent et propulsent des entrepreneurs, startups, créateurs
                        d'activité issues des quartiers (habitants des quartiers prioritaires de la politique
                        de la Ville QPV, QVA, personnes en situation de handicap, minima sociaux, étudiants boursiers...).
                    </p>

                    <p style={{cursor: 'pointer'}} className="TestElig">
                        <BUTTON_BLHS
                        content="Test d'éligibilité"
                        textColor="var(--clr-purple-text)"
                        wrapColor="var(--clr-headerColor)"
                        borderColor="var(--clr-purple-text)"
                        wrapHoverColor="black"
                        urlTo={"https://sig.ville.gouv.fr/"}
                    />

                    </p>
                </div>
            </div>

            <div className="Focus3_row_2">
                <p class="objectives_Focus3_row_2">Nos objectifs</p>
                <div class="Focus3_row_2_subs_wrapper">
                    <Focus3_sub
                        number='1'
                        title='Informer et Sensibiliser'
                        text='les jeunes et habitants des quartiers prioritaires de l’agglomération Grenobloise afin de développer des initiatives innovantes, entrepreneuriales et de découverte des métiers du numérique.'
                    />
                    <Focus3_sub
                        number='2'
                        title='Favoriser et Encourager'
                        text='les potentiels porteurs de projets ainsi que ceux qui ont une appétence pour les métiers du numérique.'
                    />
                    <Focus3_sub
                        number='3'
                        title='Préparer, Former et Animer'
                        text='les jeunes et les habitants créateurs d’activités et qui souhaitent entreprendre leur vie à travers les métiers du numérique et les technologies d’avenir'
                    />
                </div>
                <p style={{marginLeft:'auto', marginRight:'auto'}}>
                <BUTTON_BLHS
                    content = "Nous soutenir"
                    textColor="var(--clr-purple-text)"
                    wrapColor="var(--clr-headerColor)"
                    borderColor="var(--clr-purple-text)"
                    wrapHoverColor="black"
                    class="BUTTON_FOCUS4_COMP2"
                    urlTo="https://www.helloasso.com/associations/sos-jeunes-pousses"
                />
                </p>

            </div>

            <div style={{cursor: 'pointer'}} onClick={scrollNextF3  } className="ScrollDownBiggestContainer">
                <div className="ScrollDown">
                    S C R O L L
                </div>
                <div className="ArrowDownLongScroll">
                    <FontAwesomeIcon icon={faArrowRightLong}/>
                </div>
            </div>



        </div>
    )
}
