/* Import Css Style */
import './styles/Card_Focus_2.css'
/* Import React */
import {useContext} from "react";
/* Components imports */
import {DisplayContext} from "../Contexte/DisplayContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";


export const Card_Focus_2 = (props) =>{
    let setFocalisation = useContext(DisplayContext).setFocalisation
    let setSubCategory = useContext(DisplayContext).setSubCategory

    return(
        <div class={"Card_Focus_2_Container" + (!props.lift ? ' upup' : ' downdown')}
         style={{color:props.textColor, borderColor:props.borderColor, background:props.wrapColor
        , transform: props.lift ? 'none': 'translate(0px, 120px)'
        }}

         onClick={() => {
             if (!props.noContext){
                 setFocalisation(props.index);
                 setSubCategory(props.indexDB);
             }
             if (props.urlTo){
                 window.open(props.urlTo, '_blank', 'noopener,noreferrer');
             }
         }}
        >
            <p class="Card_Focus_2_Text">
                <div className="Card_Focus_2_index" style={{color: props.indexColor}}>{props.index} </div>
                {props.content}
            </p>
            <img src={props.img} alt={props.alt} class="Card_Image" />
        </div>
    )
}
