import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";

export const AdminArticleContext = createContext();


const AdminArticleContextProvider = (props) => {
    const [articles, setArticles] = useState([]);
    const [reloadArticle, setReloadArticle] = useState(true);
    const [succesAdded, setSuccesAdded] = useState(false);
    const [failError, setFailError] = useState("");
    const [failedAdded, setFailedAdded] = useState(false);
    useEffect(  () => {

        async function fetchDataArticle() {
            await searchLaunch()
        }
        fetchDataArticle();

    }, [reloadArticle])

    const searchLaunch = async () => {
        await axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles`)
            .then((res) => {setArticles(res.data);});
    };


    return (
        <AdminArticleContext.Provider
            value={{ articles, reloadArticle, setReloadArticle,
                succesAdded, setSuccesAdded,
                failError, setFailError, failedAdded, setFailedAdded
            }}
        >

            {props.children}
        </AdminArticleContext.Provider>
    );
}
export default AdminArticleContextProvider;
