/* React Imports */
import React from 'react';

/* CSS Imports */
import './styles/Dashboard.css'
import HeaderSuperAdmin from "../components/HeaderSuperAdmin";


const Dashboard = (props) => {
    return (
        <div class="DashBoard_Container" style={{color: 'black'}}>
            <HeaderSuperAdmin/>

        </div>
    )

};
export default Dashboard;
