import axios from "axios";
import { useEffect, useState, createContext } from "react";

export const CitiesContext = createContext();

const VillesContextProvider = (props) => {
    const [villes, setVilles] = useState([]);
    const [reloadVilles, setReloadVilles] = useState(true);

    useEffect( () => {
         axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/villes/`)
            .then((res) => {
                setVilles(res.data);
            });
    }, [reloadVilles]);
    return (
        <CitiesContext.Provider value={{ villes, reloadVilles, setReloadVilles }}>
            {props.children}
        </CitiesContext.Provider>
    );
};

export default VillesContextProvider;
