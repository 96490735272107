/* Import Css Style */
import './styles/NumberCircle.css'

export const NumberCircle = (props) =>{
    return(
        <div class="NumberCircle_Container"
         style={{color:props.textColor, background:props.wrapColor}}

        >
            <p class="NumberCircle">{props.number}</p>
        </div>
    )
}
