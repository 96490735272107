import "./Styles/OldAdminHeader.css";
import {Link, useLocation, useNavigate, useNavigation} from "react-router-dom";
import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightFromBracket, faPencil, faArrowLeft} from "@fortawesome/free-solid-svg-icons";

function OldAdminHeader() {
    let location = useLocation();
    let modifyTarget;
    // const csvExporter = new ExportToCsv(options);
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Data',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };
    switch (useLocation().pathname){
        case '/admin-controler/articles':
            modifyTarget = 'articles';
            break;
        case '/admin-controler/donnes':
        case '/admin-controler/donnes/modal/supprimer':
        case '/admin-controler/donnes/export':
            modifyTarget = 'donnes';
            break;
        case '/admin-controler/usersPanel':
        case '/admin-controler/usersPanel/modal/supprimer':
        case '/admin-controler/usersPanel/modal/lock':
        case '/admin-controler/usersPanel/modal/affectation':
            modifyTarget = 'user';
            break;
        default:
            modifyTarget = '';
            break;
    }
    let navigate = useNavigate();
    return(
        <div className="firstContent">
            <h2 className="bjr-user">Bonjour {localStorage.getItem('username')}
            <br/>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginTop: '40px', cursor: 'Pointer'}} onClick={()=>{navigate(-1)}} />
            </h2>
            <div className={"icons-style"}>
                {modifyTarget === "articles" && (
                    <Link to="/admin-controler/articleForm" >
                        <div className="publishContent">
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="2x"
                                color="var(--clr--green)"
                            />
                        </div>
                    </Link>
                )}
                {modifyTarget === "user" && (
                    <Link to="/admin-controler/userForm" >
                        <div className="publishContent">
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="2x"
                                color="var(--clr--orange4)"
                            />
                        </div>
                    </Link>
                )}

                {modifyTarget === "donnes" && (
                    <div >
                        <div className="publishContent" >
                            {/*<img src={Export} alt="exportIcon" title="Exporter les données" style={{cursor: 'pointer'}} onClick={() => {csvExporter.generateCsv(telechargements);}}></img>*/}
                        </div>
                    </div>
                )}

                <Link to="/admin-controler/logout"  state={{ backgroundLocation: location }}>
                    <div className="publishContent">
                        <FontAwesomeIcon
                            icon={faRightFromBracket}
                            size="2x"
                            color="var(--clr--green)"
                        />
                    </div>
                </Link>

            </div>

        </div>
    );
}


export default OldAdminHeader;
