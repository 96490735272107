/* Import Css Style */
import './styles/BUTTON_BLHS.css'
import {useState} from "react";

export const BUTTON_BLHS = (props) =>{

    const [isHover, setIsHover] = useState(true);

    function handleMouseEnter() {
        setIsHover(!isHover)
    }

    function handleMouseLeave() {
        setIsHover(!isHover)
    }

    return(
        <div style={{color:props.textColor, borderColor:props.borderColor,
            background: isHover ? props.wrapColor : props.wrapHoverColor,
            transition: 'background 1s ease',
            width: props.large ? '280px': '170px',
        }} class="BUTTON_BLHS_Container"

             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}


             onClick={() => {    window.open(props.urlTo, '_blank', 'noopener,noreferrer');}}
        >
            {props.content}
        </div>
    )
}
