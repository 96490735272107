import axios from "axios";
import { useEffect, useState, createContext } from "react";

export const RegionsContext = createContext();

const RegionContextProvider = (props) => {
    const [regions, setRegions] = useState([]);
    const [reloadRegions, setReloadRegions] = useState(true);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/regions`)
            .then((res) => {setRegions(res.data);});
    }, [regions]);
    return (
        <RegionsContext.Provider value={{ regions, reloadRegions, setReloadRegions }}>
            {props.children}
        </RegionsContext.Provider>
    );
};

export default RegionContextProvider;
