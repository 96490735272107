/* CSS Imports */
import './styles/Connexion.css'
/* Import Components */
import {Connexion_LHS} from "../subComponents/Connexion_LHS";
import {Connexion_RHS} from "../subComponents/Connexion_RHS";
import {Header_Focus1} from "../subComponents/Header_Focus1";

export const Connexion = () =>{
    return(
        <div class="Connexion_Container">
            <Connexion_LHS/>
            <Connexion_RHS/>
            <a className="HeaderRHS">
                <Header_Focus1/>
            </a>
        </div>
    )
}
