/* Import Css Style */
import './styles/Focus4_Comp2.css'
import {BUTTON_BLHS} from "./BUTTON_BLHS";
/* Import externals assets */
import stars from './../assets/Focus4/star.svg'

export const Focus4_Comp2 = () =>{
    return(
        <div class="Focus4_Comp2_Container">
            <p class="TITLE_FOCUS4_COMP2">

                <div>Prêt à choper tes subventions</div> et à propulser ton projets dans les étoiles ?

                <img
                    src={stars}
                    alt={"Étoiles"}
                    class="Star_FOCUS4_COMP2"
                    />
                <hr className="hrFocus4_Comp2"/>

            </p>
            <p class="TEXT_FOCUS4_COMP2">
                <div style={{fontSize: '1.9rem'}} className={"QuestionFocus4Comp2"}>Vous avez des questions ? </div> <br />
                Nous avons les réponses aux
                 questions que vous pouvez vous posez sur l’écosystème des structures d'accompagnement des entreprises
                et des financements.<br/> Nous vous suivons dans vos démarches
            </p>
            <BUTTON_BLHS
                content = "Contact"
                textColor="var(--clr-purple-text)"
                wrapColor="var(--clr-headerColor)"
                borderColor="var(--clr-purple-text)"
                wrapHoverColor="black"
                class="BUTTON_FOCUS4_COMP2"
                urlTo="https://www.qrcodechimp.com/page/rtez8b1x8ogw?v=chk1682186128#"
            />

        </div>
    )
}
