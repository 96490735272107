/* CSS Imports */
import './styles/Connexion_LHS.css'

/* React Imports */
import {useContext, useEffect, useState} from "react";

/* External Modules */
import {faBars, faArrowTurnRight, faRocket} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {OldAuthContext} from "../OldSuperAdmin/Context/OldAuthContext";

export const Connexion_LHS = () =>{
    const passwordField = document.querySelector("#password");
    const eyeIcon= document.querySelector("#eye");
    let navigate = useNavigate();

    const { handleSubmit,email, setEmail, password, setPassword } =
        useContext(OldAuthContext);

    const [ toggle, setToggle ] = useState( false );
    // classList.toggle("fa-eye-slash")
    useEffect(() => {
        if (eyeIcon) {
            console.log('clicked!')
            const type = toggle ? "text" : "password";
            passwordField.setAttribute("type", type);
            if (eyeIcon.classList.contains('fa-eye-slash')){
                eyeIcon.classList.remove('fa-eye-slash')
            }
            else{
                eyeIcon.classList.add('fa-eye-slash')
            }
        }
    }, [ toggle ]);

    useEffect(() => {
        if (localStorage.getItem("token") ){
            navigate('/admin-controler');
        }
    }, []);

    return(
        <div class="Connexion_LHS_Container">
            <FontAwesomeIcon icon={faArrowTurnRight} className='ReturnHOmeConnexion'
                             style={{color: 'black'}}
            onClick={() => {navigate(('/'))}}
            />
            <p className="Connexion_Message">Se connecter à mon espace</p>
            <form onSubmit={(e) => {handleSubmit(e);}}>
                <label style={{color: 'black'}}><b>Email</b></label>

                <input
                    type="email"
                    name="username"
                    id="email"
                    placeholder="Entrer votre adresse email"
                    required=""
                    onChange={(e) => setEmail(e.target.value)}
                    style={{marginBottom: '5vh'}}
                    value={email}/>
                <label style={{color: 'black'}}><b>Mot de passe</b></label>
                <p class="passcont" >
                    <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Entrer le mot de passe"
                        required=""
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}/>

                    <i className="fa-solid fa-eye" id="eye" onClick = { e => setToggle( !toggle ) }></i>
                </p>

                <p class="passwdForgotten">Mot de passe oublié?</p>


                <input type="submit" class="connecter_button" id='submit' value='Se connecter' style={{fontSize: '1.3rem'}}/>
                <div className="passwdForgotten" style={{textAlign: 'center', marginTop: '10px', marginBottom: '10px'}}>Ou</div>
                <input type="submit" class="connecter_google_button" id='submit' value='Se connecter avec Google' required style={{marginBottom: '5vh',fontSize: '1.3rem'}}/>
                <div className="passwdForgotten" style={{textAlign: 'center', fontSize: '1.2rem'}}>Créer votre compte</div>

            </form>


        </div>
    )
}
