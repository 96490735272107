import './Styles/OldSuppression.css'
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {AdminArticleContext} from "./Context/AdminArticleContext";
import axios from "axios";
import {Dialog} from "@reach/dialog";
import {Select} from "@mui/material";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";
import {CategoryContext} from "../Contexte/CategoryContext";
import {CitiesContext} from "../Contexte/CityContext";


function OldSuppression({ deleteData, page, action }) {
    let navigate = useNavigate();
    const { reloadArticle, setReloadArticle } =  useContext(AdminArticleContext);
    const { reloadCategories, setReloadCategories } = useContext(CategoryContext);
    const { articles } = useContext(AdminArticleContext);

    const { reloadSousCat, setReloadSousCat } = useContext(SubCategoriesContext);
    const { reloadVilles, setReloadVilles } = useContext(CitiesContext);
    const [ chosenId, setChosenId] = useState();

    const handleDeletData = () => {
        axios
            .delete(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/${page}/${deleteData.id}`
            )
            .then(() => {
                        setReloadArticle(!reloadArticle);
                        setReloadCategories(!reloadCategories);
                        setReloadSousCat(!reloadSousCat);
                        setReloadVilles(!reloadVilles);
                        navigate(-1);
                }
            ).catch((err) => console.log(err + `par la requete ${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/${page}/${deleteData.id}`));
    };
    const duplicateArticle = () => {
        axios
            .post(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/${page}/${deleteData.id}`
            )
            .then((response) => console.log("RESPONSE REQUETE", response))
            .then(() => {
                setReloadArticle(!reloadArticle);
                setReloadCategories(!reloadCategories);
                setReloadSousCat(!reloadSousCat);
                setReloadVilles(!reloadVilles);
                navigate(-1);
            }).catch((err) => console.log(err + `par la requete ${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/${page}/${deleteData.id}`));
    };

    const putData = (bol) => {
        if (page === "villes"){
            axios
                .put(
                    `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/villes/visible/${deleteData.id}`
                )
                .then((response) => {console.log("RESPONSE REQUETE", response); setReloadVilles(!reloadVilles)});
        }
        else{
            axios
                .put(
                    `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/${deleteData.id}`,
                    {
                        visible: bol,
                    }
                )
                .then((response) => {console.log("RESPONSE REQUETE", response);          setReloadArticle(!reloadArticle);});
        }};
    const handleVisible = () => {
        deleteData && parseInt(deleteData.visible) === 1
            ? putData(false)
            : putData(true);
        navigate(-1);
    };
    const changeLock = (article_id) => {
        axios.post(
            `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/auth/lock/${deleteData.id}`,
        )
            // .then((response) => {console.log("RESPONSE REQUETE", response);  setReloadUsers(!reloadUsers); navigate(-1)});
    };

    const changeAffectation = (id_article, id_user) => {
        axios.post(
            `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/auth/changeAffection`,{
                id_article: id_article,
                id_user: id_user,
            }
        )
            // .then((response) => {console.log("RESPONSE REQUETE", response);  setReloadUsers(!reloadUsers); navigate(-1)});
    };

    if (action === "lock"){
        return (
            <Dialog>
                <div className="fragmentContainer">
                    <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                    <div className="popUpModalDelet">
                        <div className="modalContainer">
                            <h2>
                                Êtes-vous sûr de vouloir changer l'accés de l'utilisateur {" "}
                                {deleteData.nom_utilisateur} à son article?
                            </h2>
                            <div className="buttonModalDelet">
                                <button className="buttonGreen" onClick={() => navigate(-1)}>
                                    Retour
                                </button>
                                <button
                                    className="buttonGreen"
                                    onClick={() => changeLock()}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
    if (action === "affectation"){
        return (
            <Dialog>
                <div className="fragmentContainer">
                    <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                    <div className="popUpModalDelet">
                        <div className="modalContainer">
                            <h2>
                                Quel article voulez vous que {" "} {deleteData.nom_utilisateur} posséde?
                                {/*Quel article voulez vous que {" "}  posséde?*/}
                            </h2>
                            <div className="selectDiv" style={{height: "38px", width: "80%"}}>
                                <Select
                                    placeholder="Choix de l'article"
                                    options={articles}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    closeMenuOnSelect={true}
                                    onChange={(value) => {
                                        // console.log(deleteData);
                                        // alert(deleteData.id)
                                        setChosenId(value.id)
                                    }
                                    }
                                    required={true}
                                />
                            </div>
                            <div className="buttonModalDelet">
                                <button className="buttonGreen" onClick={() => navigate(-1)}>
                                    Retour
                                </button>
                                <button
                                    className="buttonGreen"
                                    onClick={() => changeAffectation(chosenId, deleteData.id)}
                                >
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    if (action === "supprimerUser"){
        return (
            <Dialog>
                <div className="fragmentContainer">
                    <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                    <div className="popUpModalDelet">
                        <div className="modalContainer">
                            <h2>
                                Êtes-vous sûr de vouloir supprimer {" "}
                                {deleteData.nom_utilisateur} ?
                            </h2>
                            <div className="buttonModalDelet">
                                <button className="buttonGreen" onClick={() => navigate(-1)}>
                                    Retour
                                </button>
                                <button
                                    className="buttonGreen"
                                    onClick={() => handleDeletData()}
                                >
                                    Supprimer
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
    if (action === "duplicate"){
        return (
            <Dialog>
                <div className="fragmentContainer">
                    <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                    <div className="popUpModalDelet">
                        <div className="modalContainer">
                            <h2>
                                Êtes-vous sûr de vouloir{" "} dupliquer {deleteData.value} ?
                            </h2>
                            <div className="buttonModalDelet">
                                <button className="buttonGreen" onClick={() => navigate(-1)}>
                                    Retour
                                </button>
                                <button
                                    className="buttonGreen"
                                    onClick={() => duplicateArticle()}
                                >
                                    Dupliquer
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }

    return (
        <Dialog>
            <div className="fragmentContainer">
                <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                <div className="popUpModalDelet">
                    <div className="modalContainer">
                        <h2>
                            Êtes-vous sûr de vouloir{" "}
                            {action === "supprimer" ? "supprimer" : "changer la visibilité"}{" "}
                            {deleteData.value} ?
                        </h2>
                        <div className="buttonModalDelet">
                            <button className="buttonGreen" onClick={() => navigate(-1)}>
                                Retour
                            </button>
                            {action === "supprimer" && (
                                <button
                                    className="buttonGreen"
                                    onClick={() => handleDeletData()}
                                >
                                    Supprimer
                                </button>
                            )}
                            {action === "visible" && (
                                <button className="buttonGreen" onClick={() => handleVisible()}>
                                    Valider
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
export default OldSuppression;
