/* Import Css Style */
import './styles/Crew.css'

/* Components imports */
import React, {useEffect, useRef, useState} from "react";

export const Crew = (props) =>{

    return(
        <div className={"Crew_BiggestContainer"}>

        </div>
    )

}
