/* Import Css Style */
import './styles/Footer.css'

/* Components imports */
import {FooterHome_Comp1} from "../subComponents/FooterHome_Comp1";
import {FooterHome_Comp2} from "../subComponents/FooterHome_Comp2";
import {FooterHome_Comp3} from "../subComponents/FooterHome_Comp3";
import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import useOnScreen from "../handy/useOnScreen";




export const FooterHome = (props) =>{

    let scrollTop = () => {
        document.querySelector('.Focus1_Container').scrollIntoView({behavior: "smooth"
            , block: 'center'
        })
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return(
        <>
            <div className="FooterHome_Container" id="FooterHome" ref={props.myRef}>
                <FooterHome_Comp1/>
                <FooterHome_Comp3/>
                <div style={{cursor: 'pointer', whiteSpace: 'pre-wrap'}} onClick={scrollTop}
                     className="ScrollTopToHeader">
                    <div className="ScrollUptoHeader">
                    </div>
                </div>
            </div>
        </>
    )

}
