import {useContext, useEffect, useState} from "react";
import {OldAuthContext} from "./OldAuthContext";
import {useNavigate} from "react-router-dom";

function OldRequireAuth(props) {
    const {  setBadCredential,  setAccessRefused,setPassChg, access, setAccess, protectedRoute} =
        useContext(OldAuthContext);

    const navigate = useNavigate();


    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/connexion");
        }
        protectedRoute();
    }, []);



    return <>{access ? props.children : null}</>;
}

export default OldRequireAuth;
