/* Import Css Style */
import './styles/Focus2.css'
/* Components imports */
import {Header_Focus2} from "../subComponents/Header_Focus2";
/* Import React */
import {useContext, useEffect} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import {Focus2Selected} from "../subComponents/Focus2Selected";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
/* Import Assets */

export const Focus2 = () =>{

    let focalisation = useContext(DisplayContext).focalisation
    let setFocalisation = useContext(DisplayContext).setFocalisation
    let setSubCategory = useContext(DisplayContext).setSubCategory
    let setArticle = useContext(DisplayContext).setArticle

    let scrollNextF2 = () => {
        document.querySelector('.Focus3_Container').scrollIntoView({behavior: "smooth"
            , block: 'center'
        })
    }

    let initializeContextDisplay =() => {
        setFocalisation(0);
        setSubCategory(0);
        setArticle()
    }

    let focus2Render = () => {
        switch (focalisation) {
            case '01':
            case '02':
            case '03':
            case '04':
            case '05':
                return <Focus2Selected />
                break;
            default:
                return <Header_Focus2/>
        }
    }

    return(
        <section class="Focus2_Container">

            {focus2Render()}
            <div style={{cursor: 'pointer', display: 'none'}} onClick={scrollNextF2} className="ScrollDownBiggestContainer">
                <div className="ScrollDown">
                    S C R O L L
                </div>
                <div className="ArrowDownLongScroll">
                    <FontAwesomeIcon icon={faArrowRightLong}/>
                </div>
            </div>

        </section>
    )
}
