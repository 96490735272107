/* Import Css Style */
import './styles/DropDownCat.css'
import {CategoryContext} from "../Contexte/CategoryContext";
import React, {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";



export const DropDownCat = (props) => {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    let categories = useContext(CategoryContext).categories
    let setCategory = useContext(DisplayContext).setCategory
    let hideDDropDown = async () => {
        var cols = document.getElementsByClassName('CatFocus2Container');
        if (!cols) {
            return
        }
        cols[0].style.pointerEvents = 'none';
        await delay(1100);
        cols[0].style.pointerEvents = 'auto';
    }

    return(
        <p className="DropDownCatContainer">

            {
                categories &&
                categories.map((cat) => {

                    return(
                        <p class="DropDownCatItem" onClick={
                            () => {
                                setCategory(cat.id);
                                hideDDropDown();
                            }
                        }>
                            {cat.value}
                        </p>
                    )
                })
            }

        </p>
    )

}
