import "./styles/Draft.css"
import React, { useRef, useState } from "react";
// Import Swiper React components





export const Draft = () =>{

    const buffer64 = Buffer.from("ko", 'binary').toString('base64')


    return(
        <div class="Draft_Container">
                hello
        </div>
    )
}
