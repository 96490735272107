/* Import Css Style */
import './styles/Focus1.css'

/* Import Components */
import {Header_Focus1} from "../subComponents/Header_Focus1";
import {BLHS_Focus1} from "../subComponents/BLHS_Focus1";
import {BRHS_Focus1} from "../subComponents/BRHS_Focus1";

/* External Assets */
import {faArrowRightLong, faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DropDownHeaderFocus1} from "../subComponents/DropDown";
import {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import {ArticleDisplay} from "../subComponents/ArticleDisplay";



export const Focus1 = () =>{

    let isArticleSelected = useContext(DisplayContext).articleSelected

    let scrollNextF1 = () => {
        document.querySelector('.Focus2_Container').scrollIntoView({behavior: "smooth"
        , block: 'center'
        })
    }
    if (isArticleSelected){
        return (
            <ArticleDisplay/>
        )
    }
    else{
        return(
            <section className="Focus1_Container">
                <Header_Focus1/>
                <BLHS_Focus1/>
                <BRHS_Focus1/>

                <div onClick={scrollNextF1} className="ScrollDownBiggestContainer">
                    <div className="ScrollDown">
                        S C R O L L
                    </div>
                    <div className="ArrowDownLongScroll">
                        <FontAwesomeIcon icon={faArrowRightLong}/>
                    </div>
                </div>

            </section>
        )
    }
}

