/* Import Css Style */
import './styles/Focus4_Comp1.css'
/* External components */
import {Witness} from "./Witness";
/* External assets */
import {faClone} from "@fortawesome/free-solid-svg-icons"
import {useEffect, useState} from "react";

export const Focus4_Comp1 = () =>{
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    return(
        <div class="Focus4_Comp1_Container">
            <h2 class="Testimonials" >Témoignages</h2>
            {windowSize.innerWidth > 600 ? <div className="Testimonials_Wrapper">
                <Witness
                    content="“Merci à l'équipe de SOS Jeunes Pousses pour son professionnalisme, sa bienveillance et sa gentillesse. Sa connaissance de l'écosystème et en matière de startup nous a été d'une immense aide lors de l'organisation du concours Fundtruck.
Aucun doute sur la qualité de l'accompagnement de SOS Jeunes Pousses pour les jeunes startups ! 👌”"
                    ProjectName="Concours Fundtruck"
                    PersonName="Juliette S"
                    imgName="https://i.ibb.co/zr07K5M/1604587023880-modified.png"
                    urlTo="https://fundtruck.com/"
                />
                <Witness
                    content="“Nous avons beaucoup échanger avec Rachid sur le lancement de notre projet. Il a su être pertinent et objectif pour nous donner les clés de la réussite. Grâce à Rachid nous avons mis en lumière les actions possibles de développement et trouver les bons interlocuteurs. Je recommande pour toute personne porteuse d’un projet mais qui n’ose pas franchir le pas. Bravo et merci.”"
                    ProjectName="MelanineMC1R"
                    PersonName="Benito C"
                    imgName="https://i.ibb.co/mDST2wW/4128176.png"
                    urlTo="https://melaninemc1r.com/"
                />
            </div>:

                <Witness
                    content="“Merci à l'équipe de SOS Jeunes Pousses pour son professionnalisme, sa bienveillance et sa gentillesse. Sa connaissance de l'écosystème et en matière de startup nous a été d'une immense aide lors de l'organisation du concours Fundtruck.
Aucun doute sur la qualité de l'accompagnement de SOS Jeunes Pousses pour les jeunes startups ! 👌”"
                    ProjectName="Concours Fundtruck"
                    PersonName="Juliette S"
                    imgName="https://i.ibb.co/zr07K5M/1604587023880-modified.png"
                    urlTo="https://fundtruck.com/"
                />
            }

        </div>
    )
}
