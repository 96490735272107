import axios from "axios";
import {useEffect, useState, createContext, useContext} from "react";

export const CategoryContext = createContext();

const CategoriesContextProvider = (props) => {
    const [categories, setCategories] = useState([]);
    const [idCategorie, setIdCategorie] = useState("");
    const [reloadCategories, setReloadCategories] = useState(true);



    useEffect(  () => {

         axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/categories`)
            .then((res) => {setCategories(res.data)});
    }, [reloadCategories]);




    return (
        <CategoryContext.Provider
            value={{
                categories,
                reloadCategories,
                setReloadCategories,
                idCategorie,
                setIdCategorie,
            }}
        >
            {props.children}
        </CategoryContext.Provider>
    );
};

export default CategoriesContextProvider;
