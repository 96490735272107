import "./Styles/OldAdminsidebar.css";
import {useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import { faMap,faDatabase ,faCircleArrowRight, faNewspaper, faKey, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OldAuthContext} from "./Context/OldAuthContext";

function isInContent(location_path){
    if (location_path.includes('article') ||
        location_path.includes('categories') ||
        location_path.includes('selectPanel') ||
        location_path.includes('villes') ||
        location_path.includes('secteurs') ||
        location_path.includes('souscategories')||
        location_path.includes('usersPanel')
    ){
        return true
    }
    return false
}

const  OldAdminsidebar = () => {
    const [active, setActive] =  [useContext(OldAuthContext).active, useContext(OldAuthContext).setActive]
    const location = useLocation();
    return (
        <div className={active ? "navbaradmin active" : "navbaradmin"}>
            <div
                className={active ? "nav active" : "nav"}
                onClick={() => setActive(!active)}
            >
                <FontAwesomeIcon
                    icon={faCircleArrowRight}
                    size="1x"
                    color="var(--clr--two)"
                />

            </div>



            <Link to="/"className={active ? "LinkNavBar active" : "LinkNavBar"} style={{ textDecoration: 'none' }}>
                <div className={active ? "gestionuser active" : "gestionuser"} style={{ textDecoration: 'none' }}>
                    <div className="icone">
                        <FontAwesomeIcon
                            icon={faHome}
                            size="1x"
                            color="var(--clr--two)"
                        />
                    </div>
                    { active ?
                        <div className={active ? "text active" : "text"}>Home</div>
                        :   <></>
                    }
                </div>
            </Link>

            <Link to="dashboard" style={{ textDecoration: 'none' }}>
                <div className={active ? "gestionuser active" : "gestionuser"}>
                    <div className="icone">
                        { location.pathname.includes('dashboard') ?
                            <FontAwesomeIcon
                                icon={faMap}
                                size="1x"
                                color="white"
                            />
                            :
                            <FontAwesomeIcon
                                icon={faMap}
                                size="1x"
                                color="var(--clr--two)"
                            />
                        }
                    </div>
                    { active ?
                        <div className={(active ? "text active" : "text") + `${location.pathname.includes('dashboard') ? " highlighted" : " "}`}>Panorama</div>
                        :   <></>
                    }
                </div>
            </Link>

            <Link to="selectPanel" style={{ textDecoration: 'none' }}>
                <div className={active ? "gestionuser active" : "gestionuser"}>
                    <div className="icone">

                        { isInContent(location.pathname) ?
                            <FontAwesomeIcon
                                icon={faNewspaper}
                                size="1x"
                                color="white"
                            />
                            :
                            <FontAwesomeIcon
                                icon={faNewspaper}
                                size="1x"
                                color="var(--clr--two)"
                            />
                        }

                    </div>
                    { active ?
                        <div className={(active ? "text active" : "text") + (isInContent(location.pathname) ? " highlighted" : " ")}>Dashboard</div>
                        :   <></>
                    }
                </div>
            </Link>

            <Link to="donnes" style={{ textDecoration: 'none' }}>
                <div className={active ? "gestionuser active" : "gestionuser"}>
                    <div className="icone">
                        {location.pathname.includes('donnes') ?
                            <FontAwesomeIcon
                                icon={faDatabase}
                                size="1x"
                                color="white"
                            />
                            :
                            <FontAwesomeIcon
                                icon={faDatabase}
                                size="1x"
                                color="var(--clr--two)"
                            />
                        }
                    </div>
                    { active ?
                        <div
                            className={(active ? "text active" : "text") + `${location.pathname.includes('users') ? " highlighted" : " "}`}>Data
                        </div>                            :   <></>
                    }


                </div>
            </Link>



            <Link to="password" style={{ textDecoration: 'none' }}>
                <div className={active ? "gestionuser active" : "gestionuser"}>
                    <div className="icone">
                        { location.pathname.includes('password') ?
                            <FontAwesomeIcon
                                icon={faKey}
                                size="1x"
                                color="white"
                            />
                            :
                            <FontAwesomeIcon
                                icon={faKey}
                                size="1x"
                                color="var(--clr--two)"
                            />
                        }

                    </div>
                    { active ?
                        <div className={(active ? "text active" : "text") + `${location.pathname.includes('password') ? " highlighted" : " "}`}>Mot de passe</div>

                        :   <></>
                    }
                </div>
            </Link>


        </div>
    );
};

export default OldAdminsidebar;
