/* Import Css Style */
import './styles/FooterHome_Comp3.css'
import EloneIcon from "../assets/sosjp_logo_white.png";
import insta from "../assets/IconsSites/insta.svg";
import Linkedin from "../assets/IconsSites/linkedin.svg";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons'


export const FooterHome_Comp3 = () =>{
    let navigate = useNavigate();

    return(
        <div class="FooterHome_Comp3_Container">
            <img
                src='https://i.ibb.co/vV9nSrF/sosjp-logo-white-b7c5e940783d6a8a2e86-removebg-preview.png'
                alt='Icone Elone'
                className='Icon_SOSJP_Footer'
                onClick={() => {
                    navigate("/");
                    window.location.reload(false);
                }}
            />
            <div className="SOSJPADDRESS_FOOTER_ADDRESS" >
                Pour <a class="UnderLineFooter">nous trouver,</a> <br/>
                voici nos deux adresses :
            </div>
            <div class="SOSJPADDRESS_FOOTER">
                SOS Jeunes Pousses <br/>
                29 Av. Danielle Casanova, 38130 Échirolles
            </div>

            <div className="SOSJPADDRESS_FOOTER">
                Le Media Lab Chez La Capsule (Cap-Berriat)<br/>
                21 Rue Boucher de Perthes, 38000 Grenoble
            </div>



            <div className="PHONE_FOOTER" >
                <a href="tel:+33760608019">07 60 60 80 19</a>
            </div>



            <div className="ICONS_FOOTER_CONTAINER">
                Rejoignez <br/> Notre communauté <br/>
                <div className="ICONS_FOOTER">

                    <a href="https://www.instagram.com/sosjeunespousses/">
                    {/*<FontAwesomeIcon icon={faInstagram} style={{fontSize: '2.5rem', color:'white'}}/>*/}
                        <img width="40" height="40"
                             src={insta}
                             alt="Instagram"
                             />
                    </a>
                    <a href="https://www.linkedin.com/company/clubhousefrance/">
                        <img  width="40" height="40"
                             src={Linkedin}
                             alt="Linkedin"
                        />
                    </a>
                    {/*<a href="ht  tps://g.co/kgs/eEQcMo">*/}
                    {/*<FontAwesomeIcon icon={faGoogle} style={{fontSize: '2.2rem', color:'white'}}/>*/}
                    {/*</a>*/}
                </div>
            </div>

            <div className="inscrivez-vous" >
                Scanne ou clique sur le QR code  
                <a href="https://www.qrcodechimp.com/page/rtez8b1x8ogw?v=chk1682186128#">
                <img src='https://i.ibb.co/FVn7JZ6/output-onlinepngtools.png' width="110" height="110"  />
                </a>
            </div>

            <a href="https://www.qrcodechimp.com/page/rtez8b1x8ogw?v=chk1682186128#" style={{textDecoration: 'none'}}>
            <div className="Inscrivez_vous_footer" >
                 Inscrivez-vous à nos Ateliers !
            </div>
            </a>

            
            <div className="Donnez_Avis_Footer_Container" >
                <p>Donnez votre avis</p>
                <img src='https://i.ibb.co/3v24Xvj/google-icon.png' width="110"  />
                <a className="Container_Stars" href = "https://g.co/kgs/eEQcMo">
                    <FontAwesomeIcon icon={faStar} size="1rem" style={{color: 'yellow'}} />
                    <FontAwesomeIcon icon={faStar} size="1rem" style={{color: 'yellow'}}/>
                    <FontAwesomeIcon icon={faStar} size="1rem" style={{color: 'yellow'}}/>
                    <FontAwesomeIcon icon={faStar} size="1rem" style={{color: 'yellow'}}/>
                    <FontAwesomeIcon icon={faStar} size="1rem" style={{color: 'yellow'}}/>
                </a>
            </div>


            <div className="FooterHome_Copyright" >© 2023 SOSJP. Design by : &nbsp;
                <a class="UnderLineFooter"
                href="https://thinkbluestudio.com/">Think Blue Studio</a></div>

        </div>
    )
}
