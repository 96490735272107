/* Import Css Style */
import './styles/Focus2Selected.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {Card_Focus_2_Selected} from "./Card_Focus_2_Selected";
import {useContext} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import {ArticleContext} from "../Contexte/AritcleContext";
import 'react-dropdown/style.css';
import {DropDownCat} from "./DropDownCat";
import {DropDownTown} from "./DropDownTown";
import SearchBar from "../handy/SearchBar";
import EloneIcon from "../assets/ELONE_Logo_V1_white_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.svg";





export const Focus2Selected = () =>{



    let category = useContext(DisplayContext).categoryName
    let articles = useContext(ArticleContext).articles
    let ville = useContext(DisplayContext).villeName


    let focalisation = useContext(DisplayContext).focalisation
    let setFocalisation = useContext(DisplayContext).setFocalisation
    let setSubCategory = useContext(DisplayContext).setSubCategory
    let setArticle = useContext(DisplayContext).setArticle
    let nothing = () => {

    }
    let displayDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownCatContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'flex';
    }

    let hideDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownCatContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'none';
    }

    let ableDisable = () => {

        var cols = document.getElementsByClassName('DropDownCatContainer');
        if (!cols){
            return
        }
        if (cols[0].style.display == 'none'){
            displayDropDown()
        }
        else{
            hideDropDown()
        }
    }

    let displayDropDownTown = () =>{
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'flex';
    }

    let hideDropDownTown = () =>{
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'none';
    }

    let ableDisableTown = () => {
        var cols = document.getElementsByClassName('DropDownTownContainer');
        if (!cols){
            return
        }
        if (cols[0].style.display == 'none'){
            displayDropDownTown()
        }
        else{
            hideDropDownTown()
        }
    }
    let initializeContextDisplay =() => {
        setFocalisation(0);
        setSubCategory(0);
        setArticle()
    }
    // let category = "Startup"
    return(
        <div class="Focus2Selected_Container">
            <img
                src={EloneIcon}
                alt={'EloneIcon'}
                className={'Focus2EloneIcon'}
                style={{textAlign: 'center', left: '40%', marginLeft: '45%'}}
                onClick={initializeContextDisplay}
            />
            <div class="Focus2Selected_Container_title">
             Je suis
                <div class="CatFocus2Container"> {category}

                <div className="dropDownArrowContainer">
                    <FontAwesomeIcon icon={faAngleDown}
                    />
                    <DropDownCat />
                </div>
                </div>

                 de <div>

                <div
                    class="VilleFocus2Container">
                    {ville}

                <div className="dropDownArrowContainer"><FontAwesomeIcon icon={faAngleDown}/></div>
                    <DropDownTown/>
                </div>
            </div>
            </div>


                <div className="Focus2Selected_Container_Card_Container">
                    {
                        articles.map( (article, index)=> {
                            return <div

                            > <Card_Focus_2_Selected
                                index={index + 1}
                                id={article.id}
                                titre={article.titre}
                                intro={article.intro}
                                image={article.image}
                                miniature={article.hasOwnProperty('miniature') ? article.miniature : 'n'}
                            /> </div>

                        })
                    }
                </div>



        </div>
    )
}
