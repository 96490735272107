import './Styles/Test.css';
import axios from "axios";
import {useState} from "react";
import {Focus_Formulaire} from "../components/Focus_Formulaire";

const Test = (props) => {



    return (
        <div className="TestBIggestContainer" >
            <Focus_Formulaire/>
        </div>
    );
};

export default Test;
