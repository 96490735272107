// STYLES CONFIG SELECT
import "./Styles/OldModificationArticle.css";
import {AdminArticleContext} from "./Context/AdminArticleContext";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import checked from "../assets/checked.png"
import close from "../assets/close.png"
import {CitiesContext} from "../Contexte/CityContext";
import {CategoryContext} from "../Contexte/CategoryContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";
import TinyArticle from "../handy/TinyArticle";
import Select, { StylesConfig } from "react-select";
import BouttonPublier from "./BouttonPublier";

const colourStyles: StylesConfig = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "20vw",
        padding: ".5rem",
    }),
};
const OldModificationArticle = ({ modifyId }) => {
    //Rappel des Context
    const { idCategorie,setIdCategorie, reloadArticle, setReloadArticle, succesAdded, setSuccesAdded,
        failError, setFailError, failedAdded, setFailedAdded} =
        useContext(AdminArticleContext);
    const { sousCategories } = useContext(SubCategoriesContext);
    const { categories } = useContext(CategoryContext);
    const { villes } = useContext(CitiesContext);


    let navigate = useNavigate();

    //  Fonction pour modifier les données
    const modifDatas = (event) => {
        event.preventDefault();
        axios
            .put(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/${modifyId}`,
                { ...article }
            )
            .then((response) => console.log("RESPONSE REQUETE", response))
            .then(() => setReloadArticle(!reloadArticle))
            .then(() => {setSuccesAdded(false); setFailError(false); navigate(-1);})
            .catch((error) => {setSuccesAdded(false); setFailedAdded(true); setFailError(error.response.data.message)})
    };

    // Modifier l'Id de la catégorie pour avoir les sous Cat en fonction
    const handleChangeCategorie = (value) => {
        const { id } = value;
        setIdCategorie(id);
    };


    //States pour les select
    const [villesPara, setVillesPara] = useState();
    const [catPara, setCatPara] = useState();
    const [sousCatPara, setSousCatPara] = useState();
    const [secteursPara, setSecteursPara] = useState();

    // Récuperer les données

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/categories/${modifyId}`
            )
            .then((response) => {setCatPara(response.data)});
    }, []);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/villes/${modifyId}`
            )
            .then((response) => setVillesPara(response.data));
    }, []);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/sousCat/${modifyId}`
            )
            .then((response) => setSousCatPara(response.data));
    }, []);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/secteurs/${modifyId}`
            )
            .then((response) => setSecteursPara(response.data));
    }, []);

    const [article, setArticle] = useState({});

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/details/${modifyId}`
            )
            .then((res) => setArticle(res.data));
    }, []);

    const handleChange = (e, name, select) => {
        if (select) {
            let extractedValue = [];
            for (let i = 0; i < e.length; i++) {
                extractedValue.push(e[i].id);
            }
            setArticle({
                ...article,
                [name]: extractedValue,
            });
        } else if (name) {
            setArticle({
                ...article,
                [name]: e,
            });
        } else {
            setArticle({
                ...article,
                [e.target.name]: e.target.value,
            });
        }
    };

    return (
        <>
            <div className="articles-and-types">

                {succesAdded ? <div className={'msg-wrap'}>
                    <img src={checked} alt='success' className='msg-img'/>
                    <h3 className={'msg-text'}>L'article a été modifié par succés.</h3>
                </div> : null}

                {failedAdded ? <div className={'msg-wrap'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '15px'}}>
                        <img src={close} alt='refused' className='msg-img'/>
                        <h3 className={'msg-text'} style={{color: 'red'}}>L'article n'a pas été modifié pour l'erreur suivante:</h3>
                    </div>
                    <div style={{textAlign: 'center', color: 'red'}}> {failError} </div>
                </div> : null}
                {/* BLOC DE GAUCHE = ARTICLE */}
                <form className="bloc-content-row">
                    <div className="bloc-article">
                        <h3 className="titreMenu">Modifier l'article</h3>

                        <div className="article-form">
                            <input
                                className="input-article-title"
                                placeholder="Titre de l'article"
                                value={article.titre && article.titre}
                                name="titre"
                                onChange={(e) => handleChange(e)}
                            />
                            <input
                                className="input-article-intro"
                                placeholder="Intro de l'article"
                                value={article.intro ? article.intro : ""}
                                name="intro"
                                onChange={(e) => handleChange(e)}
                            />
                            <input
                                placeholder="Url de l'image (Image noir par défaut)"
                                type="url"
                                value={article.image ? article.image : ""}
                                name="image"
                                onChange={(e) => handleChange(e)}
                            />
                            <input
                                placeholder="Url de la miniature (Icone de la ville ou SOSJP par défaut)"
                                type="url"
                                value={article.miniature ? article.miniature : ""}
                                name="miniature"
                                onChange={(e) => handleChange(e)}
                            />
                            <TinyArticle
                                modifArticle={article.para1}
                                setArticleContent={handleChange}
                                target= "para1"
                            />
                            <h2 style={{color: "black"} }> Avantage : </h2>
                            <TinyArticle
                                modifArticle={article.avantage}
                                setArticleContent={handleChange}
                                target = "avantage"
                            />
                            {/*<input*/}
                            {/*  className="input-article-intro"*/}
                            {/*  placeholder="Texte présentant les avantages de l'article"*/}
                            {/*  value={article.avantage ? article.avantage : ""}*/}
                            {/*  name="avantage"*/}
                            {/*  onChange={(e) => handleChange(e)}*/}
                            {/*/>*/}
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°1"
                                    value={article.lien1 ? article.lien1 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="lien1"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°1"
                                    value={article.Text_lien1  ? article.Text_lien1 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien1"
                                />
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°2"
                                    value={article.lien2 ? article.lien2 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="lien2"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°2"
                                    value={article.Text_lien2 ? article.Text_lien2 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien2"
                                />
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°3"
                                    value={article.lien3 ? article.lien3 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="lien3"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°3"
                                    value={article.Text_lien3 ? article.Text_lien3 : ""}
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien3"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="types-articles">
                        <h3 className="titreMenu">Type d'article</h3>
                        <div className="bloc-deroulant-publier">
                            <div className="drop-down-type">
                                <div className="selectDiv">
                                    <Select
                                        // placeholder="Choix de Catégorie"
                                        placeholder={catPara ? catPara :"Choix de catégorie"}
                                        options={categories}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) =>

                                            handleChange(value.id, "id_categorie", false)
                                        }
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 1)",
                                                primary: "rgba(228, 144, 114, 1)",
                                            },
                                        })}
                                    />
                                </div>
                                <div className="selectDiv">
                                    <Select
                                        placeholder={sousCatPara ? sousCatPara :"Choix de sous-catégorie(s)"}
                                        options={sousCategories}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) =>
                                            handleChange(value.id, "sous_categorie_id", false)
                                        }
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 0.659)",
                                                primary: "rgba(228, 144, 114, 0.659)",
                                            },
                                        })}
                                    />
                                </div>
                                {/*<div className="selectDiv">*/}
                                {/*  <Select*/}
                                {/*    placeholder={secteursPara ? secteursPara : "Choix de secteur(s)"}*/}
                                {/*    options={secteurs}*/}
                                {/*    className="basic-multi-select"*/}
                                {/*    classNamePrefix="select"*/}
                                {/*    closeMenuOnSelect={true}*/}
                                {/*    onChange={(value) =>*/}
                                {/*      handleChange(value.id, "secteur_id", false)*/}
                                {/*    }*/}
                                {/*    styles={colourStyles}*/}
                                {/*    theme={(theme) => ({*/}
                                {/*      ...theme,*/}
                                {/*      borderRadius: 0,*/}
                                {/*      colors: {*/}
                                {/*        ...theme.colors,*/}
                                {/*        primary25: "rgba(228, 144, 114, 0.659)",*/}
                                {/*        primary: "rgba(228, 144, 114, 0.659)",*/}
                                {/*      },*/}
                                {/*    })}*/}
                                {/*  />*/}
                                {/*</div>*/}
                                <div className="selectDiv">
                                    <Select
                                        // placeholder="Choix de ville(s)"
                                        placeholder={villesPara ? villesPara : "Choix de ville(s)" }
                                        options={villes}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) => handleChange(value.id, "ville_id", false)}
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 0.659)",
                                                primary: "rgba(228, 144, 114, 0.659)",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="selectDiv">
                                    <div className="publishButton">
                                        <BouttonPublier
                                            article={article}
                                            collectDatas={modifDatas}
                                            type={"Modifier"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default OldModificationArticle;
