/* Import Css Style */
import './styles/FooterHome_Comp2.css'
/* External assets*/
import sosjpLogo from './../assets/sosjp_logo_white-removebg-preview.png'

export const FooterHome_Comp2 = () =>{
    return(
        <div class="FooterHome_Comp2_Container">
            <div class="FooterHome_Comp2_1">
                <img src={sosjpLogo} alt="sosjp Logo" className="footer_logo"/>
                <p class="FooterHome_Comp2_1_text">L'Association
                    "Service Assistance Dépannage" des entrepreneurs des quartiers de Grenoble-Alpes Métropole</p>
            </div>
            <div class="FooterHome_Comp2_2">
                <h2 class="FooterHome_Comp2_2">Navigation</h2>
                <p class="FooterHome_Comp2_2_item">À propos</p>
                <p class="FooterHome_Comp2_2_item">Ressources</p>
                <p class="FooterHome_Comp2_2_item">CGU</p>
                <p class="FooterHome_Comp2_2_item">Contact</p>
                <p class="FooterHome_Comp2_2_item_last">© 2023 SOSJP. Design by : <a href="https://thinkbluestudio.com/">Think Blue Studio</a></p>
            </div>
        </div>
    )
}
