import "./styles/Cgu.css"
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import "swiper/css/pagination";

import {Card_Focus_2} from "../subComponents/Card_Focus_2";
import Astronaut from "../assets/21.png";
import {Header_Focus1} from "../subComponents/Header_Focus1";
import {FooterHome} from "../components/FooterHome";



export const Cgu = () =>{
    return(
        <div class="Cgu_Container">
            <Header_Focus1/>

            <div className="contentCgu">
                <h1>Conditions Générales d’Utilisation</h1>
                <h6 className="dateCGU">
                    {" "}
                    CGU Date d’entrée en vigueur : 10/11/22{" "}
                </h6>
                <p>
                    Les présentes Conditions Générales d’Utilisation (ci-après dénommées «
                    CGU » ou « Conditions Générales d’Utilisation ») ont pour objet de
                    fixer les modalités et conditions par lesquelles nous (défini
                    ci-après) Vous (défini ci-après) fournissons un accès au site
                    <a href="https://www.sosjp.org/" className="lienSOSJP">
                        sosjp.org
                    </a>{" "}
                    (le « Site ») et aux Services définis les droits et obligations des
                    parties dans ce cadre.
                </p>
                <p>
                    Les CGU déterminent également les modalités selon lesquelles nous vous
                    autorisons à utiliser les fonctionnalités du site.
                </p>
                <p>
                    Les CGU expriment l’intégralité de l’accord entre les parties et
                    annulent et remplacent tout accord, lettre, offre ou autre document
                    écrit ou oral antérieur ayant le même objet.
                </p>
                <p>
                    Elles sont accessibles et imprimables à tout moment par un lien direct
                    en haut de chaque page du site.
                </p>
                <p>
                    Vous vous engagez à respecter les règles définies au sein des CGU à
                    notre égard ainsi qu’à l’égard des prestataires dont les Services sont
                    présents sur le site.
                </p>
                <h3>1. Exploitants du site et des Services, contact</h3>
                <p>
                    Le site et les Services (tels que définis ci-après) sont exploités par
                    la l’association Sos jeunes pousses, association loi 1901 au numéro de
                    Siret :
                    <a
                        href="https://www.verif.com/societe/SOS-JEUNES-POUSSES-907803589/"
                        target="_blank"
                    >
                        907 803 589 00015
                    </a>
                    , dont le siège social est situé{" "}
                    <a href="https://goo.gl/maps/7SvT6kzoEkcXp1dB6" target="_blank">
                        29, Avenue Danielle casanova – 38130 Échirolles.
                    </a>
                </p>
                <p>
                    {" "}
                    (« Nous ») Nous pouvons être contactés aux coordonnées suivantes,
                    notamment pour toute réclamation : Adresse postale : 29 avenue
                    Danielle Casanova 38130 Échirolles Téléphone :{" "}
                    <a href="tel:06 61 87 61 44" className="lienSOSJP">
                        06 61 87 61 44
                    </a>{" "}
                    Adresse email:{" "}
                    <a href="mailto:sosjp38@gmail.com" className="lienSOSJP">
                        sosjp38@gmail.com
                    </a>
                </p>
                <h3>2. Accès au site et éligibilité aux Services</h3>
                <p>
                    Pour accéder au site et utiliser les Services, vous devez
                    impérativement respecter les conditions suivantes (« Vous »):{" "}
                </p>
                <ul>
                    <li> Être une personne physique ; </li>
                    <li> Avoir au moins quinze (15) ans révolus</li>
                    <li>
                        Si vous avez au moins dix-huit (18) ans :{" "}
                        <ul>
                            <li>
                                Avoir la pleine capacité juridique pour contracter au titre des
                                présentes CGU ;
                            </li>
                        </ul>
                    </li>
                    <li>
                        {" "}
                        Si vous ne disposez pas de la pleine capacité juridique :{" "}
                        <ul>
                            <li>
                                {" "}
                                Vous devez obtenir l’accord de votre représentant légal avant
                                d’accéder au site et d’utiliser les Services, ce que vous
                                reconnaissez et acceptez
                            </li>
                        </ul>
                    </li>
                    <li>
                        Si vous avez entre quinze (15) ans révolus et dix-huit (18) ans:
                        <ul>
                            <li>
                                Vous devez obligatoirement obtenir l’accord de votre
                                représentant légal avant d’accéder au site et d’utiliser les
                                services, ce que vous reconnaissez et acceptez. Nous pourrons
                                vous demander à tout moment, et par tout moyen que nous jugerons
                                utile, de nous fournir la preuve de cette autorisation.
                            </li>
                        </ul>
                    </li>
                    <li> Ne pas être interdit d’accès par Nous</li>
                </ul>
                <p>Pour le service de dépôt d’offre d’emploi :</p>
                <ul>
                    <li>
                        Être dument habilité(e) à représenter la personne morale au nom de
                        laquelle vous allez utiliser ce Service.
                    </li>
                </ul>
                <p>
                    {" "}
                    Vous acceptez en outre d'acquérir, à vos frais, tout matériel,
                    logiciel et accès Internet nécessaires pour accéder au site et en
                    utiliser les Services.
                </p>{" "}
                <p>
                    Toute personne répondant aux conditions définies ci-dessus peut
                    accéder au site et en utiliser les Services.
                </p>
                <h3>3. Services </h3>
                <h4>3.1 Pour les jeunes</h4>
                <h4>4.1.1 Événements </h4>
                <p>
                    Nous mettons à votre disposition un accès à une base de données
                    d’événements autours des thématiques de l'emploi, du recrutement, de
                    la formation, et l'accompagnement, organisés par sos jeunes pousses.
                </p>
                <p>
                    Vous avez la possibilité de vous inscrire aux événements via les
                    formulaires d’inscription aux événements ou par les modalités de
                    contact directement accessibles depuis la page de l'événement.{" "}
                </p>
                <p>
                    Vous comprenez et acceptez que dans le cadre d'une inscription en
                    ligne à un événement vos données seront transférées aux organismes
                    organisateurs afin de procéder à la finalisation de votre inscription.
                </p>
                <h4>
                    3.1.2 Recherche offres d’accompagnement de parcours entrepreneuriat
                </h4>
                <p>
                    Nous mettons à votre disposition sur le site un moteur de recherche
                    d’accompagnement de parcours entrepreneuriat vous permettant d’accéder
                    aux offres pas villes. L'accès à ce moteur de recherche ne nécessite
                    pas la création d'un compte.{" "}
                </p>
                <p>
                    En cliquant sur une offre, vous êtes redirigé sur les sites des
                    différents structure et action sur un territoire.{" "}
                </p>
                <p>
                    Pour rentrer en connexion à une offre, vous devez cliquer sur le
                    bouton «Télécharger" situé en bas au milieu de l'offre.{" "}
                </p>
                <p>
                    Vous avez accès aux coordonnées de l’action ou de la structure selon
                    le mode de contact (adresse courriel, téléphone professionnel, adresse
                    de l’entreprise) et pouvez ainsi entrer en relation avec celui-ci.
                </p>
                <h4>3.1.3 Se faire recontacter par sos jeunes pousses </h4>
                <p>
                    Nous mettons à votre disposition un formulaire vous permettant de vous
                    faire recontacter par SOS jeunes pousses où vous habitez, afin d’avoir
                    des informations complémentaires et personnalisées sur l’initiative
                    sos jeunes pousses.
                </p>
                <h4>3.2 Pour les structures et actions </h4>
                <h4>3.2.1 Dépôt d’offres de parcours entrepreneurial</h4>{" "}
                <p>
                    Nous mettons à votre disposition sur le site un espace de contact
                    ayant pour objet la collecte et la diffusion d'offres d’accompagnement
                    de parcours entrepreneurial.{" "}
                </p>
                <p>
                    Cet espace est géré par Sos jeunes pousses à partir de son site
                    internet accessible à l'adresse suivante :{" "}
                    <a href="https://www.sosjp.org/" className="lienSOSJP">
                        sosjp.org
                    </a>
                </p>
                <p>
                    Les offres d’action et d’accompagnement durant le parcours
                    entrepreneurial sera déposées sur cet espace sont visibles sur le
                    site, accessibles sur le site internet{" "}
                    <a href="https://www.sosjp.org/" className="lienSOSJP">
                        sosjp.org
                    </a>
                    et transmises.{" "}
                </p>
                <p>
                    Les offres peuvent être réutilisées pour tout usage et notamment être
                    rediffusées sur des sites tiers.{" "}
                </p>
                <p>
                    Lors du dépôt de son offre, l’accompagnant doit obligatoirement saisir
                    les données suivantes : civilité, nom, prénom, téléphone professionnel
                    et adresse courriel de contact.{" "}
                </p>
                <p>
                    Les données à caractère personnel de l’accompagnant (nom et prénom du
                    recruteur, adresse courriel et numéro de téléphone professionnels)
                    sont accessibles aux porteurs de projets qui souhaitent entrer en
                    contact avec l’accompagnant afin de postuler à une offre de suivie
                    lors de sont parcours.{" "}
                </p>
                <p>
                    Les données à caractère personnel sont conservées jusqu’au terme de la
                    campagne de communication de la plateforme.{" "}
                </p>
                <p>Diffusion de contenus sur internet :</p>
                <ul>
                    <li>
                        Les accompagnants ne doient pas, sous peine de sanctions pénales,
                        mettre en ligne des contenus illégaux, notamment :{" "}
                    </li>
                    <li>Faire l’apologie de crimes contre l’humanité ;</li>
                    <li>
                        Inciter à la commission d’acte de terrorisme ou faire leur apologie
                        ;
                    </li>
                    <li>Inciter à haine raciale ;</li>
                    <li>
                        {" "}
                        inciter à la haine à l’égard de personnes à raison de leur sexe, de
                        leur orientation ou identité sexuelle ;
                    </li>
                    <li>
                        inciter à la haine à l’égard de personnes à raison de leur handicap
                        ;
                    </li>
                    <li>diffuser de la pornographie, notamment enfantine ;</li>
                    <li>
                        {" "}
                        Inciter à la violence, notamment, aux violences faites aux femmes ;
                    </li>
                    <li> Porter atteinte à la dignité humaine ;</li>
                    <li> Proférer des injures ;</li>
                    <li>
                        {" "}
                        Alléguer ou imputer un fait qui porte atteinte à l'honneur ou à la
                        considération d'une personne (diffamation).{" "}
                    </li>
                </ul>
                <p>
                    Par ailleurs, le recruteur s’engage à ne pas mettre en ligne des
                    contenus inappropriés, notamment :{" "}
                </p>
                <ul>
                    <li>Sans rapport avec l’emploi ;</li>
                    <li>
                        {" "}
                        Comportant des opinions, politiques, religieuses ou philosophiques ;
                    </li>
                    <li>Contraires aux bonnes mœurs. </li>
                </ul>
                <p>
                    Une procédure de signalement permet à tout utilisateur, connecté à son
                    espace personnel sur le site{" "}
                    <a href="https://www.sosjp.org/" className="lienSOSJP">
                        sosjp.org
                    </a>
                    , de signaler un contenu contraire aux dispositions ci-dessus. Le
                    signalement s’effectue directement sur l’offre d’emploi en cliquant
                    sur l’onglet « Contact – signaler ».
                </p>
                <h4>3.2.2 Devenir accompagnant à un événement sos jeunes pousses</h4>
                <p>
                    Nous mettons à votre disposition sur le site, au sein de la rubrique
                    Recruteurs, un formulaire de contact afin de candidater pour devenir
                    entreprise accompagnane à un événement sos jeunes pousses. Les équipes
                    de sos jeunes pousses vous recontacterons afin de discuter avec vous
                    de vos besoins de recrutement pour vérifier une éventuelle adéquation
                    avec un événement à venir. Cela ne saurait engager une quelconque
                    obligation de résultat de la part de sos jeunes pousses .
                </p>
                <h4>
                    3.2.3 Faire référencer votre offre d’accompagnement de l’initiative
                    sos jeunes pousses.
                </h4>
                <p>
                    Nous mettons à votre disposition sur le site, au sein de la rubrique
                    contact, un formulaire de contact afin de faire référencer une offre
                    d’accompagnement que vous organisez comme événement partenaire sos
                    jeunes pousses Les équipes de sos jeunes pousses vérifierons
                    l’éligibilité de votre offres
                </p>
                <h4>3.2.4 Vous faire recontacter </h4>
                <p>
                    Nous mettons à votre disposition sur le site, au sein de la rubrique
                    Contact, un formulaire de contact afin que les équipes sos jeunes
                    pousses puissent vous recontacter pour répondre à toute question que
                    vous pourriez avoir.
                </p>
                <h4>
                    3.3 Informations autour des offres de partenaires de sos jeunes
                    pousses
                </h4>
                <p>
                    Nous mettons à votre disposition toutes les informations locales
                    entourant sos jeunes pousses. Ces informations sont non exhaustives et
                    ne remplacent en aucun cas les recherches que vous pourriez faire
                    individuellement.
                </p>
                <h4> 3.4 Autres services</h4>
                <p>
                    Nous nous réservons la possibilité de proposer tout autre service que
                    nous jugerons utile, sous une forme et selon les fonctionnalités et
                    moyens techniques que nous estimerons les plus appropriés pour rendre
                    ledit service. Dans ce cas, nous mettrons à jour ces CGU afin qu’elles
                    reflètent la réalité des services proposés sur le site.
                </p>
                <h4>3.5 Accès aux Services Les Services</h4>
                <p>
                    Vous sont fournis à titre gratuit, étant entendu que vous prenez à
                    votre charge exclusive tous frais d’accès à l’Internet, ainsi que le
                    coût de vos communications.
                </p>
                <h3>4. Acceptation des présentes CGUs</h3>{" "}
                <p>
                    Votre simple accès au site et utilisation des Services valent
                    acceptation irrévocable et sans réserve des termes des présentes CGU.
                </p>
                <p>
                    Toute adhésion sous réserve est considérée comme nulle et non avenue.
                    Si vous n’acceptez pas d’être lié(e) par les présentes CGU, vous ne
                    devrez ni accéder au site, ni utiliser les Services.
                </p>
                <h3> 5. Usage des Services </h3>
                <h4>5.1 Pour le service de dépôt d’offre d’emploi</h4>{" "}
                <p>
                    Vous vous engagez à utiliser le Service de dépôt d’offres d’emploi
                    uniquement pour déposer des offres d'emploi.{" "}
                </p>
                <p>
                    En aucun cas vous n'êtes autorisé à constituer des fichiers de
                    candidatures à d’autres fins qu’un recrutement, et à plus forte raison
                    à des fins de revente ou à des fins publicitaires.
                </p>
                <h4>5.2 Pour tous les autres Services</h4>{" "}
                <p>
                    Vous vous engagez à utiliser les Services personnellement, et à ne
                    permettre à aucun tiers de les utiliser à votre place ou pour votre
                    propre compte, y compris à un autre utilisateur des services, sauf à
                    en supporter l’entière responsabilité.
                </p>
                <h3>6. Vos obligations envers nous</h3>
                <p>
                    Sans préjudice des autres obligations prévues aux présentes, Vous vous
                    engagez à respecter les obligations qui suivent:{" "}
                </p>
                <ul>
                    <li>
                        Vous vous engagez, lors de votre utilisation des Services, à
                        respecter les lois et règlements en vigueur et à ne pas porter
                        atteinte aux droits de tiers ou à l’ordre public,
                    </li>
                    <li>
                        {" "}
                        Vous reconnaissez avoir pris connaissance sur le site des
                        caractéristiques et contraintes, notamment techniques, des Services.
                    </li>
                    <li>
                        {" "}
                        Vous êtes seul(e) responsable de votre utilisation des Services,
                    </li>
                    <li>
                        {" "}
                        Vous vous engagez à faire un usage strictement personnel des
                        Services.
                    </li>
                    <li>
                        {" "}
                        Vous vous interdisez en conséquence de céder, concéder ou transférer
                        tout ou partie de vos droits ou obligations au titre des présentes à
                        un tiers, de quelque manière que ce soit,
                    </li>
                    <li>
                        {" "}
                        Vous vous engagez à nous fournir tous les documents, éléments,
                        données et informations nécessaires à la bonne exécution des
                        Services.{" "}
                    </li>

                    <li>
                        Plus généralement Vous vous engagez à coopérer activement avec nous
                        en vue de la bonne exécution des présentes et à nous informer de
                        toutes difficultés liées à cette exécution.
                    </li>
                    <li>
                        {" "}
                        Vous êtes informé(e) et acceptez que la mise en œuvre des Services
                        nécessite que vous soyez connecté(e) à internet et que la qualité
                        des Services dépend directement de cette connexion, dont vous êtes
                        seul(e) responsable.
                    </li>
                    <li>
                        {" "}
                        Vous vous engagez à nous informer immédiatement de tout acte
                        contraire aux CGUs dont vous auriez connaissance.
                    </li>
                    <li>
                        {" "}
                        Vous reconnaissez que vous utilisez le site et les Services à vos
                        propres risques, et vous êtes seul(e) et unique responsable de votre
                        respect des dispositions de l’Article 8 des présentes, et vous êtes
                        seul(e) et unique responsable, tant à notre égard qu’à l’égard des
                        tiers, de toute violation des CGU ainsi que des conséquences qui en
                        résulteraient.
                    </li>
                </ul>
                <h3>7. Ce que vous nous garantissez </h3>
                <p>
                    Vous nous garantissez contre toutes plaintes, réclamations, actions
                    et/ou revendications quelconques que nous pourrions subir du fait de
                    votre violation de l’une quelconque de vos obligations ou garanties
                    aux termes des CGUs.{" "}
                </p>
                <p>
                    En conséquence, vous vous engagez à nous indemniser de tout préjudice
                    que nous pourrions subir et à prendre à votre charge tous les
                    dommages-intérêts, ainsi que les pertes, frais (y compris, mais sans
                    limitation, les honoraires d'avocats), charges et dépens auxquels nous
                    pourrions être condamnés, ou que nous aurions à supporter notamment
                    dans le cadre d’un accord transactionnel signé par nous après avoir
                    obtenu votre accord préalable.
                </p>
                <h3>8. Comportements prohibés </h3>
                <h4>8.1 Vous vous interdisez strictement :</h4>{" "}
                <ul>
                    <li>
                        D’usurper l'identité d'une autre personne ou entité, falsifier ou
                        supprimer des mentions relatives à l'auteur, des mentions légales ou
                        obligatoires, des mentions de propriété, ou encore des intitulés sur
                        l'origine ou la source de logiciels ou d'autres informations
                        contenues dans un fichier envoyé ;
                    </li>{" "}
                    <li>
                        De promouvoir, fournir des instructions sur ou exercer des activités
                        illégales, frauduleuses ou portant atteinte aux droits ou à la
                        sécurité des tiers ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’inciter à porter atteinte à l'intégrité physique d'un groupe ou
                        d'une personne ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’utiliser le site dans un but illégal ou non autorisé ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’organiser des concours via le site sans notre autorisation écrite
                        et préalable ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’interférer avec ou interrompre le site ou les serveurs ou réseaux
                        connectés au site ou manquer à des conditions, procédures, règles ou
                        réglementations stipulées par les réseaux connectés à nos sites ;
                    </li>{" "}
                    <li>
                        D’utiliser un robot, un robot d'exploration (spider), une
                        application de recherche/récupération de sites ou un autre
                        dispositif visant à récupérer ou à indexer tout ou partie du site ou
                        à collecter des informations sur les Utilisateurs dans un but non
                        autorisé ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’accéder à tout ou partie du site par tout moyen autre qu’à travers
                        l’interface que nous fournissons ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’avoir un comportement ou entreprendre une action ou une activité
                        de nature à entraver ou à perturber le fonctionnement du site ou
                        nuire à leur réputation ainsi qu’à la nôtre et à celle de nos
                        dirigeants ;
                    </li>{" "}
                    <li>
                        {" "}
                        De s’introduire dans le système informatique d’un tiers ou de
                        s’adonner à toute activité de nature à nuire, contrôler, interférer,
                        ou intercepter tout ou partie du système informatique d’un tiers, en
                        violer l’intégrité ou la sécurité ;
                    </li>{" "}
                    <li>
                        {" "}
                        De s’adonner à tout acte de concurrence déloyale ou de parasitisme à
                        notre encontre ;{" "}
                    </li>{" "}
                    <li>
                        De porter atteinte à l’ordre public ou de violer des lois et
                        règlements en vigueur ;
                    </li>{" "}
                    <li>
                        {" "}
                        D’aider ou d’inciter, sous quelque forme et de quelque manière que
                        ce soit, à un ou plusieurs des actes et activités décrits ci-dessus
                        ;
                    </li>{" "}
                    <li>
                        {" "}
                        Et plus généralement, toute pratique détournant les Services à des
                        fins autres que celles pour lesquelles ils ont été conçus.{" "}
                    </li>
                </ul>
                <p>
                    Nous ne saurions être tenus pour responsables en cas de votre
                    non-respect des dispositions des présentes
                </p>
                <h4>8.2</h4>
                <p>
                    {" "}
                    Il vous est strictement interdit de copier et/ou de détourner à leurs
                    fins ou à celles de tiers le concept, les technologies, tout ou partie
                    des données ou tout autre élément du Site.
                </p>
                <h4>8.3 Sont également strictement interdits :</h4>
                <ul>
                    {" "}
                    <li>
                        {" "}
                        Tous comportements de nature à interrompre, suspendre, ralentir ou
                        empêcher la continuité des Services,
                    </li>{" "}
                    <li>
                        {" "}
                        Toutes intrusions ou tentatives d’intrusions dans nos systèmes,{" "}
                    </li>{" "}
                    <li> Tous détournements des ressources système du site, </li>{" "}
                    <li>
                        {" "}
                        Toutes actions de nature à imposer une charge disproportionnée sur
                        les infrastructures de ces derniers,{" "}
                    </li>{" "}
                    <li>
                        {" "}
                        Toutes atteintes aux mesures de sécurité et d’authentification,{" "}
                    </li>{" "}
                    <li>
                        {" "}
                        Tous actes de nature à porter atteinte à nos droits et intérêts
                        financiers, commerciaux ou moraux ou des Utilisateurs du site, et
                        enfin plus généralement{" "}
                    </li>{" "}
                    <li> Tout manquement aux CGUs.</li>
                </ul>
                <h4>8.4</h4>{" "}
                <p>
                    Il est strictement interdit de monnayer, vendre ou concéder tout ou
                    partie de l’accès aux Services ou au site, ainsi qu’aux informations
                    qui y sont hébergées et/ou partagées.
                </p>
                <h3>9. Sanctions des manquements</h3>{" "}
                <p>
                    Si vous manquez à l’une quelconque des dispositions des CGUs, ou plus
                    généralement, en cas d’infraction aux lois et règlements de votre
                    part, nous nous réservons le droit de :{" "}
                </p>
                <ul>
                    <li>
                        Prendre toutes mesures appropriées et engager toute action en
                        justice, et/ou avertir le cas échéant les autorités compétentes,
                        coopérer avec elles et leur fournir toutes les informations utiles à
                        la recherche et à la répression d’activités illégales ou illicites.
                    </li>
                </ul>
                <h3>10. Notre responsabilité et nos garanties envers vous </h3>
                <h4>10.1</h4>{" "}
                <p>
                    Nous nous engageons à faire nos meilleurs efforts pour fournir les
                    Services avec diligence et selon les règles de l’art, étant précisé
                    qu’il pèse sur nous une obligation de moyens, à l’exclusion de toute
                    obligation de résultat, ce que vous reconnaissez et acceptez
                    expressément.{" "}
                </p>
                <p>
                    Notre responsabilité est exclusivement limitée à la fourniture des
                    Services selon les modalités décrites dans les CGU, à l’exclusion de
                    toute autre prestation. Vous reconnaissez et acceptez en outre que les
                    Services sont fournis tels quels sans aucune garantie d’aucune sorte,
                    expresse ou implicite.
                </p>
                <h4>10.2</h4>{" "}
                <p>
                    Nous nous engageons à procéder régulièrement à des contrôles afin de
                    vérifier le fonctionnement et l’accessibilité au site. A ce titre,
                    nous nous réservons la faculté d’interrompre momentanément l’accès au
                    site pour des raisons de maintenance.{" "}
                </p>
                <p>
                    De même, nous ne saurions être tenu responsable des difficultés ou
                    impossibilités momentanées d’accès au site qui auraient pour origine
                    des circonstances qui leurs sont extérieures, la force majeure, ou
                    encore qui seraient dues à des perturbations des réseaux de
                    télécommunication.
                </p>
                <h4>10.3 </h4>
                <p>Nous ne vous garantissons pas que :</p>{" "}
                <ul>
                    {" "}
                    <li>
                        Les Services, soumis à une recherche constante pour en améliorer
                        notamment la performance et le progrès, seront totalement exempts
                        d’erreurs, de vices ou défauts,
                    </li>
                    <li>
                        Ces Services, étant standard et nullement proposés à votre seule
                        intention en fonction de vos propres contraintes personnelles,
                        répondront spécifiquement à vos besoins et attentes,{" "}
                    </li>
                    <li>
                        {" "}
                        Le site fonctionnera de manière ininterrompue, nous nous réservons
                        la faculté d’interrompre momentanément l’accès au site pour des
                        raisons de maintenance, et ne pouvant en aucun cas être tenus
                        responsables des perturbations ou défaillances du réseau internet ou
                        du réseau de télécommunication, et plus généralement qui auraient
                        leur origine dans des circonstances qui lui sont extérieures ou
                        découlant de la force majeure.{" "}
                    </li>
                    <li>
                        Nous ne pourrons en aucun cas être tenus pour responsables de toute
                        conséquence qui pourrait survenir au cours, ou à l’issue, de
                        rencontres ou prises de contact entre plusieurs Utilisateurs ou non
                        Utilisateurs, dans le cadre de l’utilisation des Services.
                    </li>{" "}
                </ul>
                <p>
                    {" "}
                    En particulier, nous ne pouvons en aucun cas être responsables si vous
                    ne parvenez pas à obtenir le résultat souhaité en utilisant les
                    Services.
                </p>
                <h4>10.4</h4>{" "}
                <p>
                    Nous ne sommes pas non plus responsables du contenu des sites internet
                    tiers vers lesquels pourraient renvoyer des liens hypertextes présents
                    sur le site.
                </p>
                <h4>10.5 </h4>
                <p>
                    En tout état de cause, la responsabilité que nous sommes susceptible
                    d’encourir au titre des CGUs est expressément limitée aux seuls
                    dommages directs avérés que vous aurez subis.
                </p>
                <h4>11. </h4>
                <p>
                    Notre propriété intellectuelle Les systèmes, logiciels, structures,
                    infrastructures, bases de données et contenus de toute nature (textes,
                    images, visuels, musiques, logos, marques, base de données, etc …) que
                    nous exploitons au sein de nos sites sont protégés par tous droits de
                    propriété intellectuelle ou droits des producteurs de bases de données
                    en vigueur.{" "}
                </p>
                <p>
                    Tous désassemblages, décompilations, décryptages, extractions,
                    réutilisations, copies et plus généralement, tous actes de
                    reproduction, représentation, diffusion et utilisation de l’un
                    quelconque de ces éléments, en tout ou partie, sans notre autorisation
                    sont strictement interdits et pourront faire l’objet de poursuites
                    judiciaires.
                </p>
                <h3>
                    12. Données à caractère personnel (à l'exception des données visées à
                    l'article 3.2.1)
                </h3>
                <h4>12.1</h4>
                <p>
                    Nous appliquons une politique de protection des données personnelles
                    dont les caractéristiques sont explicitées dans le document intitulé «
                    Politique de confidentialité », dont nous vous invitons expressément à
                    prendre connaissance.{" "}
                </p>
                <p>
                    Nous déclarons notamment respecter toutes les obligations et
                    réglementaires qui nous incombent en matière de protection des données
                    à caractère personnel visant à garantir notamment la sécurité et la
                    confidentialité des données collectées et traitées.
                </p>
                <p>
                    Nous nous engageons notamment à effectuer toutes les déclarations
                    obligatoires auprès de la Commission Nationale de l’Informatique et
                    des Libertés.
                </p>
                <h4>12.2</h4>
                <p>Vous disposez de la faculté de pouvoir nous demander : </p>
                <ul>
                    <li>D’accéder aux données vous concernant,</li>
                    <li>De rectifier vos données,</li>
                    <li>
                        De demander l’effacement de vos données, Une limitation de
                        traitement de vos données, et La portabilité de vos données.
                    </li>
                </ul>{" "}
                <p>
                    Pour les données recueillies sur la base de votre consentement
                    (cookies et profilage), vous pouvez également nous demander de
                    procéder au retrait de votre consentement à tout moment et vous
                    opposer au traitement de vos données.{" "}
                </p>
                <p>
                    Vous pouvez exercer les droits susmentionnés à tout moment en envoyant
                    une requête à
                    <a href="mailto:sosjp38@gmail.com" className="lienSOSJP">
                        sosjp38@gmail.com
                    </a>
                </p>
                <h3>13. Force Majeure </h3>
                <p>
                    Nous ne pourrons être tenus responsables vis-à-vis de vous dans
                    l’hypothèse où l’exécution de nos obligations serait retardée,
                    restreinte ou rendue impossible du fait de la survenance d’un
                    événement de force majeure. Sont notamment considérées comme cas de
                    force majeure, les hypothèses habituellement reconnues par la
                    jurisprudence et les tribunaux français.{" "}
                </p>
                <p>
                    La survenance d’un événement de force majeure suspendra dans un
                    premier temps l’exécution des Services pendant une durée qui ne pourra
                    être supérieure à trois (3) mois.{" "}
                </p>
                <p>
                    Dans l’hypothèse où le cas de force majeure se poursuivrait au-delà de
                    la période susvisée, nous pourront résilier les CGU applicables, de
                    plein droit, sans formalité judiciaire, sans préavis et sans droit à
                    indemnités de quelque nature que ce soit, par l’envoi d’une lettre
                    recommandée avec accusé de réception ayant effet immédiat.
                </p>
                <h3>14. Liens et sites tiers </h3>
                <p>
                    Nous ne pourrons en aucun cas être tenus pour responsables de la
                    disponibilité technique de sites internet ou d’applications mobiles
                    exploités par des tiers (y compris ses éventuels partenaires) auxquels
                    vous pourriez accéder par l'intermédiaire du site.{" "}
                </p>
                <p>
                    Nous n’endossons aucune responsabilité au titre des contenus,
                    publicités, et/ou services disponibles sur de tels sites et
                    applications mobiles tiers dont il est rappelé qu’ils sont régis par
                    leurs propres conditions d’utilisation.{" "}
                </p>
                <p>
                    Nous ne sommes pas non plus responsables des transactions intervenues
                    entre vous et un quelconque annonceur, professionnel ou commerçant (y
                    compris ses éventuels partenaires) vers lequel vous seriez orienté(e)
                    par l'intermédiaire du site et ne saurions en aucun cas être parties à
                    quelques litiges éventuels que ce soit avec ces tiers.
                </p>
                <h3>15. Durée des CGUs </h3>
                <p>
                    Les CGUs s’appliquent pendant toute la durée d’accès au site et
                    d’utilisation des Services.
                </p>
                <h3>16. Convention de preuve </h3>
                <p>
                    Les Parties conviennent que les données et fichiers informatiques que
                    nous enregistrons ainsi que vos fichiers informatiques feront foi
                    entre les Parties.
                </p>
                <p>
                    {" "}
                    En conséquence, les données et fichiers informatiques stockés au sein
                    des systèmes informatiques que nous exploitons ou qui sont exploités
                    pour notre compte dans des conditions raisonnables de sécurité et de
                    fiabilité, pourront être valablement utilisés comme preuve dans les
                    relations entre les Parties à l’occasion de l’utilisation du Site.{" "}
                </p>
                <p>
                    Vous pouvez accéder à ces données en envoyant une demande à l’adresse
                    suivante :{" "}
                    <a href="mailto:sosjp38@gmail.com" className="lienSOSJP">
                        sosjp38@gmail.com
                    </a>
                </p>
                <h3>17. Modifications du Site et des CGUs </h3>
                <h4>17.1</h4>
                <p>
                    Nous nous réservons notamment la possibilité de modifier et/ou
                    d’arrêter de proposer tout ou partie des Services, à tout moment à
                    notre libre discrétion et sans notification préalable.
                </p>
                <h4>17.2 </h4>
                <p>
                    Nous nous réservons la possibilité de modifier les CGU à tout moment.
                    Vous serez informé(e) de ces modifications par tout moyen utile, au
                    moins sept (7) jours avant leur entrée en vigueur. Les CGU modifiées
                    vous seront opposables dès leur mise en ligne sur le Site.{" "}
                </p>
                <p>
                    Si vous n’acceptez pas les CGU modifiées, vous devrez ne plus accéder
                    au Site et ne plus utiliser les Services.{" "}
                </p>
                <p>
                    Si vous utiliser un des Services postérieurement à l’entrée en vigueur
                    des CGU modifiées, vous serez réputé(e) avoir accepté ces
                    modifications qui vous seront pleinement applicables sans réserve.
                </p>
                <h3>18. Langue</h3>
                <p>
                    Dans l’hypothèse d’une traduction des CGUs dans une ou plusieurs
                    langues, la langue d’interprétation sera la langue française en cas de
                    contradiction ou de contestation sur la signification d’un terme ou
                    d’une disposition.
                </p>
                <h3>19. Loi applicable et juridiction</h3>
                <p>
                    Les CGUs sont régies par la loi française. Les Parties conviennent que
                    le Tribunal Judiciaire de Grenoble sera exclusivement compétent pour
                    en juger, sauf règles de procédure impératives contraires.
                </p>
            </div>
            <FooterHome
            />
        </div>
    )
}
