import "./Styles/CardSelectPannel.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faNewspaper,
    faChartColumn,
    faTableColumns,
    faChartPie,
    faCity,
    faServer,
    faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";

function getIcon(name){
    if (name === "articles"){
        return(
            <FontAwesomeIcon
                icon={faNewspaper}
                size="2x"
                color="black"
            />
        )
    }
    else if (name === "catégories"){
        return(
            <FontAwesomeIcon
                icon={faTableColumns}
                size="2x"
                color="black"
            />
        )
    }
    else if (name === "sous-catégories"){
        return(
            <FontAwesomeIcon
                icon={faChartColumn}
                size="2x"
                color="black"
            />
        )
    }
    else if (name === "villes"){
        return(
            <FontAwesomeIcon
                icon={faCity}
                size="2x"
                color="black"
            />
        )
    }
    else if (name === "secteurs"){
        return(
            <FontAwesomeIcon
                icon={faServer}
                size="2x"
                color="black"
            />
        )
    }
    else if (name === "utilisateurs"){
        return(
            <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                color="black"
            />
        )
    }
}



const CardSelectPannel = (props) =>{
    const navigate = useNavigate()
    return(
        <div className="CardSelectPanelHolder" onClick={() => {navigate(props.link)}}>
            <div className="DescriptionCardSelectPanel">
                {getIcon(props.icon)}
                <h1 className="NameCardSelectPanel"> {props.name} </h1>
            </div>
        </div>
    )
}

export default CardSelectPannel
