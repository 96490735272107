/* Import Css Style */
import './styles/Team.css'

/* Components imports */
import React, {useEffect, useRef, useState} from "react";
import insta from "../assets/IconsSites/insta.svg";
import Linkedin from "../assets/IconsSites/linkedin.svg";
// import Rachid from "../assets/Team/RachidCropped.jpeg";
import Rachid from "../assets/Team/Rachid_Djebbouri_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

export const Team = (props) =>{

    return(
        <div className={"Team_BiggestContainer"}>
            <h1 className="HeaderGrabPhrase">
                Acteur de l'entrepreneuriat
            </h1>
            <h1 className="HeaderTItleTeam">
                 L’ADN de SOSJP et d'Elone est d’être <a className="UnderLineHeader">la boussole des entrepreneurs.euses des quartiers :</a> <br/>
                Créatif, empathique avec une grande connaissance de l'écosystème entrepreneurial
                <br/>
                <br/>
                <br/>
                <a className={"HeaderTItleTeamSecond"}>
                Nous sommes spécialistes en propulsion d'entrepreneurs. <br/>
                Nous combinons notre expérience en tant que porteur de projet navigant pendant + de  8 ans dans
                    l'écosystème entrepreneurial. Nous créons votre parcours d'entrepreneur afin que vous obteniez toutes les subventions dont vous êtes éligibles ainsi qu'un accompagnement <a a className="UnderLineHeader" style={{color: 'white'}}>sur mesure</a>
                    </a>
            </h1>
            <div className={"Team_Container"}>

                <div className={"Team_Member"}>
                    <div className="trapeze">
                        <img
                            src={Rachid}
                            alt="Rachid Djebbouri"
                            className="Member_Photo"
                        />
                    </div>

                    <div className="propsContainer">
                    <div className={"Name_Member"}>Rachid Djebbouri</div>
                    <div className={"Function_Member"}>Delegué Général</div>

                    <div className={"Social_Member"}>
                        <a className={"phone_Member"}
                           href="tel:+33760608019">
                            <FontAwesomeIcon className="Social_Web_SubMember" icon={faPhone} />
                        </a>
                            <a href="https://www.linkedin.com/in/numeriquepourtous/" className={"phone_Member"}>
                                <FontAwesomeIcon className="Social_Web_SubMember" icon={faLinkedin} />
                            </a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )

}
