/* CSS Imports */
import './styles/Connexion_RHS.css'
/* External assets*/
import computerUser from './../assets/5.png'
import eloneLogo from './../assets/logo_white_01.png'
import {Header_Focus1} from "./Header_Focus1";


export const Connexion_RHS = () =>{
    return(
        <div class="Connexion_RHS_Container">
            <div class='Text_Connexion_RHS'>
                <img
                    src={eloneLogo}
                    alt='Logo de Elone'
                    className='Logo_Connexion_RHS'
                />
                <p class='content_Connexion_RHS'>
                    Elone la plateforme "sérvice assistance dépannage" des créateurs d'activité

                </p>
            </div>
            <p className="ImgContainer">
            <img
                src={computerUser}
                alt='Man on rocket'
                className='computerUser_Connexion_RHS'
            />
                </p>

        </div>
    )
}
