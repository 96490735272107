/* React Imports */
import React, {useContext, useEffect, useRef, useState} from 'react';

/* CSS Imports */
import './styles/Home.css'


/* Components imports */
import {Focus1} from "../components/Focus1";
import {Focus2} from "../components/Focus2";
import {Focus3} from "../components/Focus3";
import {Focus4} from "../components/Focus4";
import {FooterHome} from "../components/FooterHome";
import {DisplayContext} from "../Contexte/DisplayContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons";
import {useParallax} from "react-scroll-parallax";
import Plx from "react-plx";
import useOnScreen from "../handy/useOnScreen";
import {Donation} from "../components/Donation";
import {Team} from "../components/Team";

const Home = (props) => {
    const myRef = useRef(null);
    // const isVisible = useOnScreen(myRef)
    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const Focus1ParallaxData = [
        {
            start: "self",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ],

        },
    ];
    const Focus2ParallaxData = [
        {
            start: "self",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        },
        {
            start: "0vh",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        }

    ];

    const Focus3ParallaxData = [
        {
            start: "0vh",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        },
        {
            start: "0vh",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        }

    ];


    const Focus4ParallaxData = [
        {
            start: "0vh",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        },
        {
            start: "0vh",
            duration: "1000vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        }

    ];

    const Focus5ParallaxData = [
        {
            start: "0vh",
            duration: "0vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        },
        {
            start: "0vh",
            duration: "0vh",
            properties: [
                {
                    startValue: 1,
                    endValue: 1,
                    property: "brightness"
                }
            ]
        }

    ];
    const elt = document.querySelector('.Focus1_Container')
    function isInViewport(element) {
        if(!element){
            console.log('not found')
            return false
        }
        const rect = element.getBoundingClientRect();
        console.log(rect.bottom <= 20)
        return (
            rect.bottom <= 20
        );
    }
    const [isVisible, setIsVisible] = useState(true);
    function logit() {
        setIsVisible(isInViewport(document.querySelector('.Header_Focus1_Container')));
    }
    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });

    return (
        <div class="Home_Container">
            <Plx parallaxData={Focus1ParallaxData} >
                <Focus1/>
            </Plx>
            <Plx parallaxData={Focus2ParallaxData} style={{backgroundColor: 'var(--clr-purple2)'
            }}>
                <Focus2/>
            </Plx>
            <Plx parallaxData={Focus3ParallaxData} >
               <Focus3/>
            </Plx>
            <Plx parallaxData={Focus4ParallaxData} >
            <Focus4/>
            </Plx>
            <Team/>
            {/*<Plx parallaxData={Focus5ParallaxData} >*/}
            <FooterHome
            myRef={myRef}
            />
            {/*</Plx>*/}

            {/*<div className="GoToBottom" onClick={executeScroll} style={{visibility: isVisible ? 'hidden' : 'visible'}}>*/}
            {/*    <FontAwesomeIcon icon={faAngleDoubleLeft} style={{fontSize: '2rem'}}/>*/}

            {/*</div>*/}

            <a className="contact_icon_fix" href="https://www.qrcodechimp.com/page/rtez8b1x8ogw?v=chk1682186128#">
                 <img src="https://i.ibb.co/tJKk788/contact-icon-removebg-preview.png" width='70'/>
                  </a>
        </div>
    )

};

export default Home;
// export default class Home extends React.Component {
//     render() {
//         return <h1>Hello, {this.props.name}</h1>;
//     }
// }

