import React, {useEffect, useState} from "react";
import axios from "axios";
import './Styles/OldDashboard.css'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateRight} from "@fortawesome/free-solid-svg-icons";
import {faMagnifyingGlassPlus} from "@fortawesome/free-solid-svg-icons";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons";

const OldDashboard = () => {
    const [svgContent, setSvgContent] = useState("Clique sur le boutton");
    const [reloadImage, setReloadImage] = useState(false);
    const [largeur, setLargeur] = useState(1800);
    const [hauteur, setHauteur] = useState(100);
    function createMarkup(result) {
        return { __html: `${result}` };
    }

    useEffect( () => {
        axios
            .post(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/dashboard`,
                {'largeur': largeur, 'hauteur': hauteur},
            )
            .then((res) => setSvgContent(res.data))
            .catch((err) => setSvgContent(err))
        ;
    }, [reloadImage]);




    const getDashboard = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/dashboard`
            )
            .then((res) => setSvgContent(res.data));
    };


    const reload = async () =>{
        setSvgContent("")
        setReloadImage(!reloadImage)
    }

    const zoomIn = async () =>{
        if (!largeur < 50){
            setLargeur(largeur - 100)
            setHauteur(hauteur - 25)
        }
        setReloadImage(!reloadImage)
    }
    const zoomOut = async () =>{
        setLargeur(largeur + 100)
        setHauteur(hauteur + 25)
        setReloadImage(!reloadImage)
    }

    return(
        <div className="Bigdashboard_Container">
            <div className='IconsContainer'>
                <div onClick={reload} style={{cursor: 'pointer'}}>
                    <FontAwesomeIcon
                        icon={faArrowRotateRight}
                        size='2x'
                        color='var(--clr-orange)'
                        className='reloadIconDashboard'
                    />
                </div>
                <div onClick={zoomOut} style={{cursor: 'pointer'}}>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlassMinus}
                        size='2x'
                        color='var(--clr-orange)'
                        className='reloadIconDashboard'
                    />
                </div>
                <div onClick={zoomIn} style={{cursor: 'pointer'}}>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlassPlus}
                        size='2x'
                        color='var(--clr-orange)'
                        className='reloadIconDashboard'
                    />
                </div>
            </div>
            <p
                id="svgcontainer"
                dangerouslySetInnerHTML={createMarkup(svgContent)}
            ></p>




        </div>




    )
}

export default OldDashboard;
