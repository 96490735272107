/* React Imports */
import React from 'react';

/* CSS Imports */
import './styles/header_super_admin.css'
import eloneLogo from '../../assets/ELONE_Logo_V1_white_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.svg'
import {faGear, faUser, faBell} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const HeaderSuperAdmin = (props) => {
    return (
        <div class="HeaderSuperAdmin_Container" style={{color: 'black'}}>
            <img
                src={eloneLogo}
                alt='Elone_Logo'
                class="EloneLogoAdminHeader"
            />


            <div className="SuperAdmin_Chars">
                <div className="email_superadmin">
                    <FontAwesomeIcon
                        icon={faUser}
                        className="UserIcon"
                    />
                    R.dj@gmail.com
                </div>

                <FontAwesomeIcon
                    icon={faGear}
                    className="GearIcon"
                />

                <FontAwesomeIcon
                    icon={faBell}
                    className="GearIcon"
                />
            </div>
        </div>
    )

};
export default HeaderSuperAdmin;
