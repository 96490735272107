/* Import Css Style */
import './styles/BRHS_Focus1.css'
/* External assets*/
import manOnRocket from './../assets/10_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.png'


export const BRHS_Focus1 = () =>{
    return(
        <div class="BRHS_Focus1_Container">
            <img
                src={manOnRocket}
                alt='Man on rocket'
                className='manOnRocket_BRHS'
            />
        </div>
    )
}
