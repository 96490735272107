import "./Styles/OldListSousCas.css";
import {DataGrid} from "@mui/x-data-grid";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";

import Select, { StylesConfig } from "react-select";

const colourStyles: StylesConfig = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "20vw",
        padding: ".5rem",
    }),
};

const OldListSousCas = ({ setDeleteData }) => {

    // PARTIE SOUS CATEGORIE

    const { sousCategories, reloadSousCat, setReloadSousCat } = useContext(
        SubCategoriesContext
    );
    const [newSousCategorie, setNewSousCategorie] = useState("");


    let location = useLocation();

    const nouvelleSousCategorie = () => {
        axios
            .post(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/souscategories`, {
                ...newSousCategorie,
            })
            .then((response) => console.log("RESPONSE REQUETE", response))
            .then(() => {setReloadSousCat(!reloadSousCat);setChooseSelectCategorie();})
            .catch((error) =>
                console.error(
                    "---Erreur envoi sous-categorie--- ",
                    error.validationErrors
                )
            );
    };

    const handleChangeNewSousCategorie = (e) => {
        setNewSousCategorie({
            nom_sous_categorie: e.target.value,
        });
        console.log("Nouvelle sous categorie à inscrire", newSousCategorie);
    };

    // PARTIE CATEGORIE

    const [selectCategorie, setSelectCategorie] = useState();
    const [chooseSelectCategorie, setChooseSelectCategorie] = useState();

    const handleChangeCategorie = (value) => {
        const { id } = value;
        setChooseSelectCategorie(id);
        console.log("Choix de sous categorie => l'Id correspondant :", id);
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/categories`)
            .then((response) => setSelectCategorie(response.data));
    }, []);

    return (
        <>
            <div className="bloc-content-column">
                <div className="containerHeaderCat">
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '99%'}}>
                        <h3 className="titreMenu">Liste des sous-categories</h3>
                        <div  className="ContainerNewCat">
                            <div className="newCategoContent">

                                <div className="newCategoContent">
                                    <input
                                        className="newCategoInput"
                                        type="text"
                                        name="myInput"
                                        placeholder="Nouvelle catégorie"
                                        size="30"
                                        required
                                        onChange={handleChangeNewSousCategorie}
                                    ></input>
                                    <button className="button2 adminButton" onClick={nouvelleSousCategorie}>
                                        Ajouter sous-catégorie
                                    </button>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <DataGrid
                    style={{ height: 500 }}
                    columns={[
                        {
                            field: "id",
                            headerName: "ID",
                            headerClassName: "headerTableau",
                            maxWidth: 70,
                            flex: 0.5,
                            align: "left",
                            headerAlign: "left",
                        },
                        {
                            field: "value",
                            headerName: "Sous-catégories",
                            headerClassName: "headerTableau",
                            minWidth: 110,
                            flex: 0.5,
                            align: "left",
                            headerAlign: "left",
                        },
                        {
                            field: "action",
                            headerName: "Action",
                            headerClassName: "headerTableau",
                            minWidth: 105,
                            flex: 0.5,
                            align: "center",
                            headerAlign: "center",
                            renderCell: (field) => (
                                <div className="actionIcon2">
                                    <Link
                                        to="./modal/editer"
                                        state={{ backgroundLocation: location }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPencil}
                                            size="1x"
                                            color="var(--clr-orange)"
                                            className="editIcon"
                                        />
                                    </Link>
                                    <Link
                                        to="./modal/supprimer"
                                        state={{ backgroundLocation: location }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            size="1x"
                                            color="var(--clr-orange)"
                                            className="deletIcon"
                                        />
                                    </Link>
                                </div>
                            ),
                        },
                    ]}
                    sx={{
                        //REGLAGE GENERAL DU TABLEAU
                        fontFamily: "var(--ff-body)",
                        fontSize: "var(--fs-body)",
                        color: "var(--clr-green)",
                        borderColor: "var(--clr-green)",
                        boxShadow: "5px 5px 5px var(--shadowColor)",
                        width: "100%",
                        padding: "8px",
                        "& .MuiDataGrid-cell:hover": {},
                    }}
                    onRowClick={(datas) => {
                        setDeleteData(datas.row);
                    }}
                    rows={sousCategories}
                    rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                    pagination
                />

            </div>
        </>
    );
};
export default OldListSousCas;
