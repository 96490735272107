/* Import React */
import { useEffect, useState, createContext } from "react";
import axios from "axios";


export const DisplayContext = createContext();
const DisplayContextProvider = (props) => {
    const [focalisation, setFocalisation] = useState(0);


    const [subCategory, setSubCategory] = useState(0);
    const [subCategoryName, setSubCategoryName] = useState("");


    const [category, setCategory] = useState(37);
    const [categoryName, setCategoryName] = useState("");

    const [ville, setVille] = useState(1);
    const [villeName, setVilleName] = useState("Grenoble Alpes Métropole");

    const [article, setArticle] = useState();
    const [articleContent, setArticleContent] = useState([]);

    const [reloadHome, setReloadHome] = useState(false);

    const[articleSelected, setArticleSelected] = useState(false);

    useEffect(() => {
        setReloadHome(!reloadHome)
    }, [focalisation])

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles/${article}`)
            .then((res) =>{
                setArticleContent(res.data);
                if (res.data){
                    setArticleSelected(true)
                }
                else{
                    setArticleSelected(false)
                }
            });
    }, [article]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/souscategories/${subCategory}`)
            .then((res) =>{
                setSubCategoryName(res.data['nom_sous_categorie']);
            });
    }, [subCategory]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/categories/${category}`)
            .then((res) =>{
                setCategoryName(res.data['nom_categorie']);

            });
    }, [category]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/villes/${ville}`)
            .then((res) =>{
                setVilleName(res.data['nom_ville']);

            });
    }, [ville]);

    return (
        <DisplayContext.Provider
            value={{ focalisation, setFocalisation, setSubCategory, subCategory, subCategoryName, category, categoryName, setCategory,ville, setVille, setVilleName,villeName,articleSelected, setArticleSelected,
                article, setArticle, articleContent, setArticleContent
            }}
        >
            {props.children}
        </DisplayContext.Provider>
    );
};

export default DisplayContextProvider;
