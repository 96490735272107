import "./Styles/OldModificationArticle.css";
import checked from "../assets/checked.png";
import close from "../assets/close.png";
import {AdminArticleContext} from "./Context/AdminArticleContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";
import {CategoryContext} from "../Contexte/CategoryContext";
import {CitiesContext} from "../Contexte/CityContext";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import TinyArticle from "../handy/TinyArticle";
import Select from "react-select";
// STYLES CONFIG SELECT
const colourStyles: StylesConfig = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "20vw",
    }),
};
const OldArticleForm = () => {
    //Rappel des Context
    const { idCategorie,setIdCategorie, reloadArticle, setReloadArticle, succesAdded, setSuccesAdded,
        failError, setFailError, failedAdded, setFailedAdded} =
        useContext(AdminArticleContext);
    const { sousCategories } = useContext(SubCategoriesContext);
    const { categories } = useContext(CategoryContext);
    const { villes } = useContext(CitiesContext);
    let navigate = useNavigate();


    //Création state ajour article avec valeurs pas défault
    const [article, setArticle] = useState({ visible: false, user_id: 1 });
    const [catSet, setCatSet] = useState();

    const ajoutDatas = (event) => {
        event.preventDefault();
        axios
            .post(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/articles`, {
                ...article,
            })
            .then((response) => console.log("RESPONSE REQUETE", response))
            .then(() => setReloadArticle(!reloadArticle))
            .then(() => {setSuccesAdded(true); setFailedAdded(false); navigate(-1)})
            .catch((error) => {setSuccesAdded(false); setFailedAdded(true);  setFailError(error.response.data.message)});
        setIdCategorie("");
    };

    // Désactiver la validation en cliquant sur le bouton entrée

    document.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }

    });


    //Ajout des infos dans la state
    const handleChange = (e, name, select) => {
        if (select) {
            let extractedValue = [];
            for (let i = 0; i < e.length; i++) {
                extractedValue.push(e[i].id);
            }
            setArticle({
                ...article,
                [name]: extractedValue,
            });
        } else if (name) {
            setArticle({
                ...article,
                [name]: e,
            });
        } else {
            setArticle({
                ...article,
                [e.target.name]: e.target.value,
            });
        }
    };

    // Modifier l'Id de la catégorie pour avoir les sous Cat en fonction
    const handleChangeCategorie = (value) => {
        const { id } = value;
        setIdCategorie(id);
    };

    return (
        <>
            <div className="articles-and-types">
                {succesAdded ? <div className={'msg-wrap'} style={{flexDirection: 'row'}}>
                    <img src={checked} alt='success' className='msg-img'/>
                    <h3 className={'msg-text'}>L'article a été ajouté par succés.</h3>
                </div> : null}

                {failedAdded ? <div className={'msg-wrap'}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '15px'}}>
                        <img src={close} alt='refused' className='msg-img'/>
                        <h3 className={'msg-text'} style={{color: 'red'}}>L'article n'a pas été ajouté pour l'erreur suivante:</h3>
                    </div>
                    <div style={{textAlign: 'center', color: 'red'}}> {failError} </div>
                </div> : null}


                {/* BLOC DE GAUCHE = ARTICLE */}
                <form className="bloc-content-row" id='goodForm' onSubmit={ajoutDatas}>
                    <div className="bloc-article">
                        <h3 className="titreMenu">Nouvel article</h3>
                        <div className="article-form">

                            <input className="input-article-title"
                                   placeholder="Titre de l'article *"
                                   id = "requiredFormField"
                                   name="titre"
                                   onChange={(e) => handleChange(e)}
                                   required={true}
                            />


                            <input
                                className="input-article-intro"
                                placeholder="Intro de l'article *"
                                id = "requiredFormField"
                                name="intro"
                                onChange={(e) => handleChange(e)}
                                required={true}
                            />
                            <input
                                placeholder="Url de l'image (Image noir par défaut)"
                                type="url"
                                name="image"
                                onChange={(e) => handleChange(e)}
                            />
                            <input
                                placeholder="Url de la miniature (Icone de SOSJP ou de la ville par défaut)"
                                type="url"
                                name="miniature"
                                onChange={(e) => handleChange(e)}
                            />
                            <h2 style={{color: "black"}}> Contenu * :</h2>
                            <TinyArticle setArticleContent={handleChange} target = "para1" />
                            <h2 style={{color: "black"} }> Avantage* : </h2>
                            <TinyArticle
                                modifArticle={article.avantage}
                                setArticleContent={handleChange}
                                target = "avantage"
                            />
                            {/*<input*/}
                            {/*  className="input-article-intro"*/}
                            {/*  placeholder="Texte présentant les avantages de l'article"*/}
                            {/*  name="avantage"*/}
                            {/*  onChange={(e) => handleChange(e)}*/}
                            {/*  required={true}*/}
                            {/*/>*/}
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°1"
                                    onChange={(e) => handleChange(e)}
                                    name="lien1"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°1"
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien1"
                                />
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°2"
                                    onChange={(e) => handleChange(e)}
                                    name="lien2"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°2"
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien2"
                                />
                            </div>
                            <div style={{display: 'flex'}}>
                                <input
                                    placeholder="URL du lien à télécharger N°3"
                                    onChange={(e) => handleChange(e)}
                                    name="lien3"
                                />
                                <div style={{width: '4%'}}>
                                </div>
                                <input
                                    placeholder="Texte du lien à télécharger N°3"
                                    onChange={(e) => handleChange(e)}
                                    name="Text_lien3"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="types-articles">
                        <h3 className="titreMenu">Type d'article</h3>
                        <div className="bloc-deroulant-publier">
                            <div className="drop-down-type">
                                <div className="selectDiv" >
                                    <Select
                                        placeholder="Choix de la catégorie *"
                                        options={categories}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) => {
                                            handleChange(value, "categorie_id", false);
                                        }
                                        }
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 0.659)",
                                                primary: "rgba(228, 144, 114, 0.659)",
                                            },
                                        })}
                                        required={true}
                                    />
                                </div>

                                <div className="selectDiv">
                                    <Select
                                        isMulti
                                        placeholder="Choix de sous-catégorie(s) *"
                                        options={sousCategories}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) =>
                                            handleChange(value, "sous_categorie_id", true)
                                        }
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 0.659)",
                                                primary: "rgba(228, 144, 114, 0.659)",
                                            },
                                        })}
                                    />
                                </div>

                                <div className="selectDiv">
                                    <Select
                                        isMulti
                                        placeholder="Choix de ville(s) *"
                                        options={villes}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        closeMenuOnSelect={true}
                                        onChange={(value) => handleChange(value, "ville_id", true)}
                                        styles={colourStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "rgba(228, 144, 114, 0.659)",
                                                primary: "rgba(228, 144, 114, 0.659)",
                                            },
                                        })}
                                    />
                                </div>
                                <div className="publishButton" form='goodForm'>
                                    <button  type="submit" className="adminButton button2">
                                        Ajouter l'article
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default OldArticleForm;
