import './Styles/OldEditName.css';
//*IMPORT CSS//*
import "@reach/dialog/styles.css";
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {CategoryContext} from "../Contexte/CategoryContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";
import {CitiesContext} from "../Contexte/CityContext";
import axios from "axios";

import { Dialog } from "@reach/dialog";
import Select from "react-select";

function OldEditName({
                      deleteData,
                      page,
                      edit,
                      select,
                      name,
                      result,
                      value2,
                      set,
                      edit2,
                      sousCategorie,
                  }) {
    let navigate = useNavigate();

    // Reload
    const { reloadCategories, setReloadCategories } =
        useContext(CategoryContext);
    const { reloadSousCat, setReloadSousCat } = useContext(SubCategoriesContext);
    const { reloadVilles, setReloadVilles } = useContext(CitiesContext);



    const handleEditName = () => {
        axios
            .put(`${process.env.REACT_APP_HOST}${process.env.REACT_APP_PORT}/${page}/${deleteData.id}`, {
                [edit]: nouvelleAppelation,
                [edit2]: value2,
            }) // les [] permettent de recuperer la valeur de la key edit
            .then((response) => console.log("RESPONSE REQUETE", response));
        setReloadCategories(!reloadCategories);
        setReloadSousCat(!reloadSousCat);
        setReloadVilles(!reloadVilles);
        navigate(-1);
    };

    /* MODIF DE L INPUT A LA SAISIE*/
    const [nouvelleAppelation, setNouvelleAppelation] = useState(
        deleteData.value
    );

    //Que deviens nouvelle appelation ?

    const handleChangeEdit = (e) => {
        setNouvelleAppelation(e.target.value);
    };
    // generique A METTRE ICI pour reprendre chaque element a modifier, nom_cat, sous cat, secteur ville

    return (
        <Dialog>
            {" "}
            <div className="fragmentContainer">
                <div className="backContainerAdmin" onClick={() => navigate(-1)}></div>
                <div className="popUpModalEdit">
                    <div className="modalContainer">
                        <h2>Changer d'appelation</h2>
                        <div className="holderChangeValue">
                            <input
                                type="text"
                                name="changement"
                                id="changeValue"
                                onChange={handleChangeEdit}
                                placeholder={deleteData.value}
                            />
                        </div>


                        {select && (
                            <Select
                                name={`Séléctionner ${name}`}
                                result={result}
                                value={value2}
                                set={set}
                            />
                        )}

                        <div className="buttonModalDelet">
                            <button className="buttonGreen" onClick={() => navigate(-1)}>
                                Annuler
                            </button>

                            <button
                                className="buttonGreen"
                                onClick={() => handleEditName(edit)}
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default OldEditName;

