/* Import React */
import './App.css';

/* Import External components */
import Home from "./screens/Home";
import {Connexion} from "./screens/Connexion";
import DisplayContextProvider, {DisplayContext} from "./Contexte/DisplayContext";
//*IMPORT REACT//*
import {Routes, Route, useLocation, Navigate, BrowserRouter} from "react-router-dom";
import CategoriesContextProvider, {CategoryContext} from "./Contexte/CategoryContext";
import SubCategoriesContextProvider from "./Contexte/SubCategoryContext";
import ArticleContextProvider from "./Contexte/AritcleContext";
import {Draft} from "./screens/Draft";
import {Cgu} from "./screens/Cgu";
import CityContext from "./Contexte/CityContext";
import VillesContextProvider from "./Contexte/CityContext";

import {useContext, useEffect, useState} from "react";
import RegionContextProvider, {RegionsContext, regionsContext} from "./Contexte/RegionContext";
import Dashboard from "./SuperAdmin/Screens/Dashboard";
import OldAuthContextProvider from "./OldSuperAdmin/Context/OldAuthContext";
import OldRequireAuth from "./OldSuperAdmin/Context/OldRequireAuth";
import OldPanelAdmin from "./OldSuperAdmin/OldPanelAdmin";
import OldSelectPanel from "./OldSuperAdmin/OldSelectPanel";
import OldListeArticles from "./OldSuperAdmin/OldListeArticles";
import {useSessionStorage} from "./handy/useSessionStorage";
import AdminArticleContextProvider, {AdminArticleContext} from "./OldSuperAdmin/Context/AdminArticleContext";
import OldSuppression from "./OldSuperAdmin/OldSuppression";
import OldModificationArticle from "./OldSuperAdmin/OldModificationArticle";
import OldArticleForm from "./OldSuperAdmin/OldArticleForm";
import Test from "./handy/Test";
import OldLogout from "./OldSuperAdmin/OldLogout";
import OldListeCat from "./OldSuperAdmin/OldListeCat";
import OldListSousCas from "./OldSuperAdmin/OldListSousCas";
import OldListeVille from "./OldSuperAdmin/OldListeVille";
import OldEditName from "./OldSuperAdmin/OldEditName";
import OldDashboard from "./OldSuperAdmin/OldDashboard";
import {APropos} from "./screens/APropos";
import {Crew} from "./screens/Crew";

function App() {



  const [modifyId, setModifyId] = useSessionStorage("modifyId", "");
  const [deleteData, setDeleteData] = useState("");
  const [modifArticle, setModifArticle] = useSessionStorage("modifArticle",{});
  const { regions, idRegion, setIdRegion } = useContext(RegionsContext);
  const { categories, idCategorie, setIdCategorie } = useContext(CategoryContext);

    let location = useLocation();
  let backgroundLocation = location.state && location.state.backgroundLocation;
  return (
<AdminArticleContextProvider>
<VillesContextProvider>
  <OldAuthContextProvider>
        <SubCategoriesContextProvider>
            <DisplayContextProvider>
                <ArticleContextProvider>
                <div className="App">

                <Routes location={backgroundLocation || location}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<APropos />} />
                <Route path="/crew" element={<Crew />} />
                {/*<Route path="/connexion" element={<Connexion />} />*/}
                <Route path="/Cgu" element={<Cgu />} />
                {/*<Route path="/bassam" element={<Test />} />*/}


                <Route
                    path="/admin-controler"
                    element={
                        <OldRequireAuth >
                            <OldPanelAdmin
                                admin = {true}
                            />
                         </OldRequireAuth>
                    }
                >
                    <Route path="articleForm" element={<OldArticleForm />} />
                    <Route
                        path="selectPanel"
                        element={
                            <OldSelectPanel
                                admin = {true}
                            />
                        }
                    >
                    </Route>

                    <Route
                        path="articles"
                        element={
                            <OldListeArticles
                                modifyId={modifyId}
                                setModifyId={setModifyId}
                                setModifArticle={setModifArticle}
                                modifArticle={modifArticle}
                                setDeleteData={setDeleteData}
                                deleteData={deleteData}
                            />
                        }
                    />

                    <Route
                        path="categories"
                        element={
                            <OldListeCat
                                // setModifArticle={setModifArticle}
                                setDeleteData={setDeleteData}
                            />
                        }
                    />

                    <Route
                        path="sousCategories"
                        element={<OldListSousCas setDeleteData={setDeleteData} />}
                    />
                    <Route
                        path="villes"
                        element={<OldListeVille setDeleteData={setDeleteData} />}
                    />
                    <Route path="dashboard" element={<OldDashboard />} />


                    <Route
                        path="modification-article"
                        element={
                            <OldModificationArticle
                                modifyId={modifyId}
                                setModifyId={setModifyId}
                                modifArticle={modifArticle}
                                setModifArticle={setModifArticle}
                            />
                        }
                    />
                </Route>
                </ Routes>
                    {backgroundLocation &&
                        (
                            <Routes>
                                <Route
                                    path="/admin-controler/logout"
                                    element={ <OldLogout/>}
                                />

                                <Route
                                    path="admin-controler/articles/modal/visible"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"articles"}
                                            action={"visible"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/articles/modal/supprimer"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"articles"}
                                            action={"supprimer"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/articles/modal/duplicate"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"duplicate"}
                                            action={"duplicate"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/villes/modal/visible"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"villes"}
                                            action={"visible"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/categories/modal/supprimer"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"categories"}
                                            action={"supprimer"}
                                        />
                                    }
                                />

                                <Route
                                    path="admin-controler/sousCategories/modal/supprimer"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"souscategories"}
                                            action={"supprimer"}
                                        />
                                    }
                                />

                                <Route
                                    path="admin-controler/villes/modal/supprimer"
                                    element={
                                        <OldSuppression
                                            deleteData={deleteData}
                                            page={"villes"}
                                            action={"supprimer"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/categories/modal/editer"
                                    element={
                                        <OldEditName
                                            deleteData={deleteData}
                                            page={"categories"}
                                            edit={"nom_categorie"}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/villes/modal/editer"
                                    element={
                                        <OldEditName
                                            deleteData={deleteData}
                                            page={"villes"}
                                            edit={"nom_ville"}
                                            edit2={"region_id"}
                                            select={true}
                                            name={"la région"}
                                            result={regions}
                                            value2={idRegion}
                                            set={setIdRegion}
                                        />
                                    }
                                />
                                <Route
                                    path="admin-controler/sousCategories/modal/editer"
                                    element={
                                        <OldEditName
                                            deleteData={deleteData}
                                            page={"sousCategories"}
                                            edit={"nom_sous_categorie"}
                                            name={"la catégorie"}
                                            result={categories}
                                            set={setIdCategorie}
                                            sousCategorie={true}
                                        />
                                    }
                                />


                            </Routes>
                        )

                    }


                </div>


                </ArticleContextProvider>
            </DisplayContextProvider>
         </SubCategoriesContextProvider>
      </OldAuthContextProvider>
    </VillesContextProvider>
</AdminArticleContextProvider>
  );
}

export default App;
