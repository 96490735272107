/* CSS Imports */
import './styles/DropDownHeaderFocus1.css'

/* React Imports */
import React, {useContext} from "react";
/* External Components */
import {DisplayContext} from "../Contexte/DisplayContext";
import {SubCategoriesContext} from "../Contexte/SubCategoryContext";

export const DropDownHeaderFocus1 = (props) => {

    let setFocalisation = useContext(DisplayContext).setFocalisation
    let setSubCategory = useContext(DisplayContext).setSubCategory
    let SubCategories = useContext(SubCategoriesContext).sousCategories

    let focalisation = useContext(DisplayContext).focalisation

    return (
        <div class="DropDownContainer">
{/*            <div class="DropDownItem"
                onClick={ () => {setFocalisation('01'); setSubCategory("82")}}
                 style={{color: focalisation === '01'? 'var(--clr-purple8)': ''}}
            >Je débute</div>
            <div class="DropDownItem"
                 onClick={ () => {setFocalisation('02'); setSubCategory("83")}}
                 style={{color: focalisation === '02'? 'var(--clr-purple8)': ''}}
            >J’ai besoin de conseils</div>
            <div class="DropDownItem"
                 onClick={ () => {setFocalisation('03'); setSubCategory("84")}}

                 style={{color: focalisation === '03'? 'var(--clr-purple8)': ''}}
            >Je cherche des financements</div>
            <div class="DropDownItem"
                 onClick={ () => {setFocalisation('04'); setSubCategory("85")}}

                 style={{color: focalisation === '04'? 'var(--clr-purple8)': ''}}
            >Je cherche des locaux</div>
            <div class="DropDownItem"
                 onClick={ () => {setFocalisation('05'); setSubCategory("99")}}
                 style={{color: focalisation === '05'? 'var(--clr-purple8)': ''}}
            >Je veux gagner à un concours</div>*/}

            {
                SubCategories.map(
                    (sub, index) => {
                        let subCat = sub.sub_categorie_id;
                        let index_string = '0' + index;
                        return (
                            <div>
                                <div className="DropDownItem"
                                     onClick={() => {
                                         setFocalisation(index);
                                         setSubCategory(subCat)
                                     }}
                                     style={{color: focalisation === index ? 'var(--clr-purple8)' : ''}}
                                >
                                    {sub.value}
                                </div>
                            </div>
                        )
                    }
                )
            }
        </div>
    )

};
