/* Import Css Style */
import './styles/Witness.css'

export const Witness = (props) =>{
    return(
        <div class="Witness_Container">
        <p class="witness_content">
            {props.content}
        </p>
        <p class="Person">
            <img src={props.imgName} alt={'hmm'} class="Person_icon" />
            <p class="Person_Name">{props.PersonName}</p>
            <p class="Project_Name" style={{cursor: 'pointer'}}
               onClick={() => {    window.open(props.urlTo, '_blank', 'noopener,noreferrer');}}
            >{props.ProjectName}</p>
        </p>
        </div>
    )
}
