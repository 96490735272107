import "./Styles/OldPanelAdmin.css";
import {useContext} from "react";
import {OldAuthContext} from "./Context/OldAuthContext";
import {Outlet} from "react-router-dom";
import OldAdminsidebar from "./OldAdminsidebar";
import OldAdminHeader from "./OldAdminHeader";


const OldPanelAdmin = () => {
    const [active, setActive] =  [useContext(OldAuthContext).active, useContext(OldAuthContext).setActive]
    return (
        <div className={"panel-admin-container"} >

            <OldAdminsidebar />
            <div className={active ? "content-container active" : "content-container"}>
                <OldAdminHeader />
                <Outlet />
            </div>


        </div>
    );
};
export default OldPanelAdmin;
