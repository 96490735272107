/* Import Css Style */
import './styles/Focus4.css'
/* Externals imports */
import {Focus4_Comp1} from "../subComponents/Focus4_Comp1";
import {Focus4_Comp2} from "../subComponents/Focus4_Comp2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";

export const Focus4 = () =>{

    let scrollNextF4 = () => {
        document.querySelector('.FooterHome_Container').scrollIntoView({behavior: "smooth"
            , block: 'center'
        })
    }
    return(
        <div class="Focus4_Container">
            <Focus4_Comp1/>
            <Focus4_Comp2/>
            <div style={{cursor: 'pointer'}} onClick={scrollNextF4} className="ScrollDownBiggestContainer">
                <div className="ScrollDown">
                    S C R O L L
                </div>
                <div className="ArrowDownLongScroll">
                    <FontAwesomeIcon icon={faArrowRightLong}/>
                </div>
            </div>
        </div>
    )
}
