/* Import Css Style */
import './styles/Header_Focus1.css'
/* External assets*/
import sosjpIcon38 from './../assets/sosjp_logo_white__SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.png'
import sosjpIcon400 from './../assets/sos_black_SosJeunesPousses_Entrepeneuriat_Entreprendre_Quartier_QPV_Grenoble_Metropole_Echirolles_Marketing_Communication_BuisnessPlan_BP_FrenchTech_Incubateur_Accompagnement_Feminin_Financement_Subvention.png'
/* External Modules */
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faBars, faRoadCircleXmark, faPersonCircleXmark, faCircleXmark, faRightLong, faBurger, faBarsStaggered} from "@fortawesome/free-solid-svg-icons"
/* Import React Component */
import {Link, Navigate, useNavigate} from "react-router-dom";
import {DropDownHeaderFocus1} from "./DropDown";
import {useContext, useRef, useState} from "react";
import {DisplayContext} from "../Contexte/DisplayContext";
import SearchBar from "../handy/SearchBar";
import {CitiesContext} from "../Contexte/CityContext";

export const Header_Focus1 = () =>{
    function MyTrapezoid    ({points, width, height}) {
        return (
            <svg width={width} height={height} className="MyTrapezoid">
                <polygon points={points} fill="none" stroke="dimgrey" strokeWidth={2} className="MyTrapezoid2"/>
            </svg>
        );
    }
    let [burger, setBurger] = useState(true);

    let navigate = useNavigate();
    let focalisation = useContext(DisplayContext).focalisation
    let isArticleSelected = useContext(DisplayContext).articleSelected
    const { villes } = useContext(CitiesContext).villes
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    let DropDownBurger = () =>{
        var cols = document.getElementsByClassName('Menu_List_Phone');
        if (!cols){

            return
        }

        cols[0].style.top = '53px';
        setBurger(!burger);

    }

    let SwipUpBurger = () =>{
        var cols = document.getElementsByClassName('Menu_List_Phone');
        if (!cols){
            return
        }
        cols[0].style.top = '-250px';
        setBurger(!burger);


    }

    let displayDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'flex';
        setBurger(!burger);
    }

    let hideDropDown = () =>{
        var cols = document.getElementsByClassName('DropDownContainer');
        if (!cols){
            return
        }
        cols[0].style.display = 'none';
        setBurger(!burger);

    }


    if (windowSize.current[0] < 600){
        return(
            <>
                <ol className='Menu_List_Phone'>
                    <li
                        onClick={() => {
                            navigate("/about");
                            window.location.reload(false);
                        }}
                    >
                        Qui nous-sommes?
                    </li>
                    <li onClick={() => {
                        navigate("/APropos")
                    }}>La Team
                    </li>
                    <li onClick={() => {
                        navigate("/cgu")
                    }}>CGU
                    </li>
                    <li onClick={() => {
                        navigate("/connexion")
                    }}>Elone
                    </li>
                    <li onClick={() => {
                        navigate("/connexion")
                    }} style={{marginBottom: '10px'}}>Connexion
                    </li>

                    {/*<img src={Astr} className="AstronautBurger"/>*/}
                </ol>
        <div className="TrapezePhone">
            <img
                src={sosjpIcon38}
                className="SOSJPHEADERPhone"
            />
            <div className={"DiagonalPhone"}/>
            <FontAwesomeIcon icon={faBarsStaggered} className="PhoneBuger"  onClick={burger? DropDownBurger : SwipUpBurger} style={{zIndex: 4}}/>


        </div>
        </>
        )

    }
    return(
        <>
            <div className="Header_Focus1_Container_2">
                <div className={"Diagonal"}/>
                <img
                    src={sosjpIcon38}
                    className="SOSJPHEADER"
                    onClick={() => {navigate("/")}}
                />
                <div className={"HeaderActionsContainer"}>
                    <div className={"ItemHeader"}>

                    <a
                    className="TextoHeader"
                        onClick={() => {
                            navigate("/about");
                            window.location.reload(false);
                        }}
                    >
                        Qui nous-sommes?
                    </a>


                        {/* <a className="TextoHeader">Qui nous-sommes?</a> */}
                        <hrHeader/>
                    </div>
                    <div className={"ItemHeader"}>
                        <a className="TextoHeader">La Team</a>
                        <hrHeader/>
                    </div>
                    <div className={"ItemHeader"}>
                        <a className="TextoHeader">ELONE</a>
                        <hrHeader/>
                    </div>
                    <div className={"ItemHeader"}>
                        <a className="TextoHeader" onClick={() => {navigate("/cgu")}}> CGU</a>
                        <hrHeader/>
                    </div>

                    <div className={"ItemHeader"}>
                        <a className="TextoHeader" onClick={() => {navigate("/connexion")}}> Connexion  </a>
                        <hrHeader/>
                    </div>
                </div>


            </div>
        </>
            )
}
