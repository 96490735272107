import "./Styles/OldSelectPanel.css"
import CardSelectPannel from "./CardSelectPannel";

const OldSelectPanel = () => {
    return (
        <div className="SelectPanelBigContainer">
            <CardSelectPannel
                name="Articles"
                icon="articles"
                msg="Gérer mes articles"
                link="/admin-controler/articles"
            />
            <CardSelectPannel
                name="Catégories"
                icon="catégories"
                msg="Gérer les catégories"
                link="/admin-controler/categories"
            />
            <CardSelectPannel
                name="Sous catégories"
                icon="sous-catégories"
                msg="Gérer les sous-catégories"
                link="/admin-controler/sousCategories"
            />

            <CardSelectPannel
                name="Villes"
                icon="villes"
                msg="Gérer les villes"
                link="/admin-controler/villes"
            />

        </div>
    )
}

export default OldSelectPanel;
