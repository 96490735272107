/* Import Css Style */
import './styles/FooterHome_Comp1.css'

/* external assets */
import BPISosJeunesPoussesEntrepreneuriat from './../assets/BPI.png'
import citelab from './../assets/citelab.jpg'
import LogoGrenobleAlpesMetropoleSosJeunesPoussesEntrepreneuriat from './../assets/Metropole.png'
import LogoServiceCivique from './../assets/Logo_Service_civique.png'
import LogoCapBerriat from './../assets/Logo-Cap-Berriat.png'
import LogoSoccerDeRue from './../assets/soccer_de_rue.png'
import NewsFm from './../assets/NewsFm.png'
import StreetCoiff from './../assets/StreetCoiff.jpg'
import UGPPME from './../assets/UGPPME.png'
import Shaolin from './../assets/Shaolin-Shadow.png'
import LeTransfo from './../assets/Le_Transfo.png'
import frenchTechTremplin from './../assets/frenchTechTremplin.png'
import act4Talent from './../assets/act4Talend.png'
import prefIsere from './../assets/Pref.png'
import addie from './../assets/ADIE_LOGO_RVB_1000px-removebg-preview.png'
import laCapsule from './../assets/La-Capsule.png'
import Apec from './../assets/Apec.png'
import PepiteOzer from './../assets/pepite-ozere.png'
import logoCci from './../assets/CCI.png'
import CMA from './../assets/CMA.png'
import FrenchTechAlpes from './../assets/French-Tech-Alpes.png'
import ReseauEntreprendreIsere from './../assets/réseau-entreprendre-isere.png'
import Poussada from './../assets/poussada.png'
import Soleeo from './../assets/Soleeo.png'
import MLSI from './../assets/MLSI.png'
import FDVA from './../assets/FDVA.png'
 
export const FooterHome_Comp1 = () =>{

    const handleClick = (LINK) => {
        const win = window.open(LINK, "_blank");
        win.focus();
    };

    return(
        <div class="FooterHome_Comp1_Container">
            <div class="TextFooterHome_Comp1">
                Parce qu'il faut être financé par <br/>les meilleurs <div style={{fontSize: '2.5rem', fontWeight: 'bolder'}} class={"UnderLineHeader"}>pour grandir</div>

            </div>
            <div class="FooterHome_Comp1_logos_containers" style={{ gridTemplateColumns:'1fr 1fr 1fr 1fr'}}>
                <img onClick={() => handleClick("https://www.grenoblealpesmetropole.fr/")} src={LogoGrenobleAlpesMetropoleSosJeunesPoussesEntrepreneuriat} alt="Métropole grenoble" className="Metropole_Grenoble_FooterHome_Comp1" />
                <img onClick={() => handleClick("https://www.isere.gouv.fr/")} src={prefIsere} alt="Préfécture de l'isère" className="Pref_Isere" />

                <img src={BPISosJeunesPoussesEntrepreneuriat} alt="Logo BPI" onClick={() => handleClick("https://www.bpifrance.fr/")} />
                <img onClick={() => handleClick("https://www.echirolles.fr/")} src={"https://i.ibb.co/d47R1v4/ville-echirolles-1.png"} alt="Ville Echirolles" />
                <img style={{gridRow: 2, gridColumn: 2}} onClick={() => handleClick("https://www.isere.fr/")} src={"https://www.isere.fr/sites/default/files/cd38-logo2018-couleurs_-_web.png"} alt="Isére département" />
                <img style={{gridRow: 2, gridColumn: 3}} onClick={() => handleClick("https://www.associations.gouv.fr/FDVA.html")} src={FDVA} alt="FDVA" />

            </div>
            <div style={{marginTop:'150px'}}> </div>

            <div className="TextFooterHome_Comp1">
                Parce qu'il faut être reconnu par <br/>les meilleurs <div
                style={{fontSize: '2.5rem', fontWeight: 'bolder'}} className={"UnderLineHeader"}>pour briller</div>

            </div>





            <div className="FooterHome_Comp1_logos_containers" >
                <img onClick={() => handleClick("https://www.citeslab.fr/")} src={citelab} alt="CitesLab" />

                <img src={LogoServiceCivique} alt="Logo Service Civique" onClick={() => handleClick("https://www.service-civique.gouv.fr/")}/>
                <img src={act4Talent} alt="Logo act4talent" onClick={() => handleClick("https://www.act4-talents.fr/")}/>

                <img src={frenchTechTremplin} alt="Logo frenchTechTremplin" onClick={() => handleClick("https://lafrenchtech.com/fr/la-france-aide-les-startup/tremplin/")}/>
 </div>

            <div style={{marginTop:'150px'}}> </div>
            <div className="TextFooterHome_Comp1">
                Parce qu'il faut s'entourer <br/>des meilleurs <div
                style={{fontSize: '2.5rem', fontWeight: 'bolder'}} className={"UnderLineHeader"}>pour réussir</div>


            </div>

            <div className="TextFooterGroup_Comp1">
               <div style={{fontWeight: 'bold'}} className={"UnderLineHeader"}>Pour être accompagné afin d'entreprendre</div>
            </div>


            <div className='FooterHome_Comp1_logos_containers' style={{marginBottom: '5vh'}}>
            <img src={LogoCapBerriat} alt="Logo Cap Berriat" onClick={() => handleClick("http://www.cap-berriat.com/")}/>
            <img src={addie} alt="Logo D'Addie" onClick={() => handleClick("https://www.adie.org/")}/>
            <img src={Apec} alt="Logo De L'Apec" onClick={() => handleClick("https://www.apec.fr/")}/>
            <img src={logoCci} alt="Logo De La CCI" onClick={() => handleClick("https://www.cci.fr/")}/>
            <img src={CMA} alt="Logo De La CMA" onClick={() => handleClick("https://www.artisanat.fr/")}/>
            <img src={PepiteOzer} alt="Logo Pepite Ozer" onClick={() => handleClick("https://www.ozer-entrepreneuriat.fr/")}/>
            <img src={FrenchTechAlpes} alt="Logo French Tech Alpes" onClick={() => handleClick("https://www.ftalps.com/territoires/grenoble/")}/>
            <img src={ReseauEntreprendreIsere} alt="Logo Reseau Entreprendre Isere" onClick={() => handleClick("https://www.reseau-entreprendre.org/isere/")}/>
           
            </div>

            <div className="TextFooterGroup_Comp1">
               <div style={{fontWeight: 'bold'}} className={"UnderLineHeader"}>Pour avoir un local/bureau</div>
            </div>


            <div className='FooterHome_Comp1_logos_containers noamrg' style={{ marginTop: '2vh', marginBottom: '5vh', gridTemplateRows: '1fr', gridTemplateColumns:'1fr 1fr 1fr'}}>
            <img src={laCapsule} alt="Logo La Capsule" onClick={() => handleClick("http://www.cap-berriat.com/")}/>
            <img src={Poussada} alt="Logo Poussada" onClick={() => handleClick("https://www.lapousada.org/")}/>
            <img src={LeTransfo} alt="Logo De La transfo" onClick={() => handleClick("https://www.facebook.com/LeTransfoGrenoble/")}/>


 </div>




        <div className="TextFooterGroup_Comp1">
               <div style={{fontWeight: 'bold'}} className={"UnderLineHeader"}>Pour potentiellement avoir une rémunération durant son aventure entrepreneuriale </div>
        </div>

            <div className="FooterHome_Comp1_logos_containers" style={{marginBottom: '30px', gridTemplateRows: '1fr', gridTemplateColumns:'1fr 1fr 1fr'}}>

            <img src={Soleeo} alt="Logo Soleeo" onClick={() => handleClick("https://www.soleeo.fr/")} style={{marginTop: '2vh', marginBottom: '2vh'}}/>
            <img src={MLSI} alt="Logo MLSI" onClick={() => handleClick("https://www.missionlocale-si.com/")}/>
            <img  src='https://www.pole-emploi.fr/files/live/sites/PE/files/images/Logos/header-logo2021-pe-fr.svg' alt="Logo pole d'emploie" onClick={() => handleClick("https://www.pole-emploi.fr/accueil/")}/>

                
            </div>



            <div className="TextFooterGroup_Comp1" style={{fontWeight: 'bold', marginBottom: '20px'}} >
               <div className={"UnderLineHeader"} >Pour communiquer avec nos partenaires</div>
        </div>

            <div className="FooterHome_Comp1_logos_containers">

         <img src={NewsFm} alt="Logo De News Fm" onClick={() => handleClick("https://www.radio-newsfm.com/")}/>


                <img src={LogoSoccerDeRue} alt="Logo Soccer De Rue" onClick={() => handleClick("https://soccerderue.fr/")}/>
                <img src={StreetCoiff} alt="Logo De Street'Coiff" onClick={() => handleClick("https://m.facebook.com/people/Streetcoiff/100057660060466/")}/>
                <img src={Shaolin} alt="Logo De ShaolinShadow" onClick={() => handleClick("https://www.shaolinshadow.com/")}/>
                {/* <img src={UGPPME} alt="Logo De UGPPME" onClick={() => handleClick("https://www.ugppme.com/")}/> */}
                
            </div>






        </div>
    )
}
